<template>
  <div id="legal">
    <div class="container-fluid">
      <div class="container">
        <div class="row align-items-center justify-content-center">

          <div class="col-12 text-start">

            <h3 class="color text-center">Aviso legal</h3>


            <p class="color h5" style="margin-top: 50px">AVISO LEGAL Y CONDICIONES GENERALES DE USO</p>
            <p>El acceso, navegación y utilización del sitio web <a target="_blank" href="https://conficaravan.com/">https://conficarvan.com </a> (en adelante “Sitio Web”) implica la aceptación expresa y sin reservas de todos los términos de las presentes Condiciones de Uso, teniendo la misma validez y eficacia que cualquier contrato celebrado por escrito y firmado. Su observación y cumplimiento será exigible respecto de cualquier persona que acceda, navegue o utilice el sitio web. Si bien, no está de acuerdo con los términos expuestos, no acceda, navegue o utilice el sitio web. </p>


            <p class="color h5" style="margin-top: 50px">IDENTIFICACIÓN</p>
            <p>
              Titular: CONFISERGUR DE GESTIÓ ROVIRA I ASSOCIATS, SL (en adelante, La Correduría) <br>
              Domicilio social:Carrer Camí de la Tolosa, 9 desp. 6 Barcelona. <br>
              CIF: B62867387 <br>
              Inscrita en el Registro Mercantil de Barcelona, folio 0116, tomo 35059, hoja número 259533, inscripción 1ª <br>
              Inscrita en el Registro Administrativo Especial de Mediadores de Seguros y sus Altos Cargos con el número de clave J399GC <br>
              Teléfono: 938 892 969 <br>
              Correo electrónico: info@confisergur.com
            </p>


            <p class="color h5" style="margin-top: 50px">OBJETO</p>
            <p> Las presentes Condiciones de Uso regulan el acceso, navegación y utilización de este sitio web, sin prejuicio que la LA CORREDURIA se reserva el derecho a modificar la presentación, configuración y contenido del mismo, así como las condiciones requeridas para su acceso y/o utilización. El acceso y utilización de los contenidos del sitio web después de la entrada en vigor de sus modificaciones o cambios suponen la aceptación de los mismos.</p>
            <p> LA CORREDURIA se reserva el derecho a modificar los términos y condiciones aquí estipuladas, total o parcialmente, publicando cualquier cambio en la misma forma en que aparecen estas Condiciones de Uso o mediante cualquier tipo de comunicación dirigida a los usuarios.</p>
            <p> De la misma manera informamos a los usuarios sobre cuáles son sus derechos y sus obligaciones en relación con los contenidos expuestos a través de sitio web, logotipos y marcas utilizadas, así como las responsabilidades que puedan derivar del acceso al sitio web.</p>
            <p> A efectos de la interpretación de las presentas Condiciones de Uso, entendemos que una persona pasa a ser usuaria en el momento en la que esta acepta las Condiciones de Uso y la Política de Privacidad expuestas en el sitio web, bastando para esto que lo visite. </p>




            <p class="color h5" style="margin-top: 50px">DERECHOS DE LA PROPIEDAD INTELECTUAL E INDUSTRIAL</p>
            <p>LA CORREDURIA es titular o, si procede, cuenta con las licencias correspondientes sobre los derechos de explotación de propiedad intelectual e industrial del sitio web, así de como todos los contenidos ofrecidos en el mismo, incluyendo la propia plataforma, textos, fotografías o ilustraciones, logotipos, marcas, grafismos, diseños, interfaces, o cualquier otra información o contenido, y los servicios disponibles a través del mismo. Las referencias a marcas o nombres comerciales registrados, u otros signos distintivos, ya sean titularidad de LA CORREDURIA o de terceras empresas, llevan implícita la prohibición sobre su uso sin el consentimiento de LA CORREDURIA o de sus legítimos titulares. En ningún momento, excepto manifestación expresa en contra, el acceso, navegación o utilización del sitio web y/o de sus contenidos confiere al usuario ningún derecho sobre signos distintivos en él incluidos. </p> 
            <p>Quedan reservados todos los derechos de propiedad intelectual e industrial sobre los contenidos del sitio web, y, en particular, queda prohibido modificar, copiar, reproducir, comunicar públicamente, transformar o distribuir, por cualquier medio y bajo cualquier forma, la totalidad o parte de los contenidos incluidos en el sitio web, para propósitos públicos o comerciales, sino se cuenta con la autorización previa, expresa y por escrito de LA CORREDURIA o, si procede, del titular de los derechos correspondientes. </p>
            <p> En el caso que el usuario envíe información de cualquier tipo a LA CORREDURIA a través de cualquiera de los canales habilitados a este efecto, el usuario declara, garantiza y acepta que tiene derecho a hacerlo libremente, que esta información no infringe ningún derecho de la propiedad intelectual, industrial, secreto comercial o cualquier otro derecho de terceros, y que esta información no tiene carácter confidencial y no es perjudicial para terceros.</p>
            <p> El usuario reconoce asumir la responsabilidad, dejando indemne al prestador para cualquier comunicación que suministre personalmente o a su nombre, llegando esta responsabilidad sin restricción de la exactitud, legalidad, originalidad y titularidad de la misma. Si el usuario tuviera conocimiento de la existencia de algún contenido ilícito, ilegal, contrario a las leyes o que pudiera suponer una infracción de derechos de la propiedad intelectual y/o industrial, tendrá que notificar inmediatamente a LA CORREDURIA a través de la dirección de correo electrónico info@confisergur.com para que esta pueda proceder a la adopción de las medidas oportunas. </p>






            <p class="color h5" style="margin-top: 50px">REGLAS DE USO DEL SITIO WEB</p>
            <p>No está permitido y, por lo tanto, sus consecuencias serán de responsabilidad exclusiva del usuario, el acceso o la autorización del sitio web con fines ilegales o no autorizados,
              con o sin finalidad económica. En particular, y sin que el siguiente listado tenga carácter absoluto, queda prohibido: <br><br>
              <ul style="line-height: 30px; margin-left: 40px"> 
                <li> ▪ Utilizar el sitio web de ninguna manera que pueda provocar daños, interrupciones, ineficiencias o defectos en su funcionamiento o en el ordenador de un tercero.</li>
                <li> ▪ Utilizar el sitio web para la transmisión, instalación o publicación de cualquier virus, código malicioso u otros programas o archivos perjudiciales.</li>
                <li> ▪ Utilizar el sitio web para recoger datos de carácter personal de otros usuarios.</li>
                <li> ▪ Utilizar el sitio web de forma ilegal, en contra de la buena fe, la moral y el orden público.</li>
                <li> ▪ Acceder sin autorización a cualquier sección del sitio web, a otros sistemas o redes conectadas al sitio web, a ningún servidor de LA CORREDURIA, ni a los servicios ofrecidos a través del sitio web, por medio del pirateo o falsificación, extracción de contraseñas o cualquier otro medio ilegítimo.</li>
                <li> ▪ Romper o intentar romper, las medidas de seguridad o autenticación del sitio web o de cualquier red conectada a este, o las medidas de seguridad o protección inherentes a los contenidos ofrecido en el sitio web.</li>
                <li> ▪ Llevar a término alguna acción que provoque una saturación desproporcionada o innecesaria en la infraestructura del sitio web o en los sistemas o redes de LA CORREDURIA, así como en los sistemas o redes conectadas al sitio web.</li>
                <li> ▪ Impedir el normal desarrollo de un evento, concurso, promoción o cualquier otra actividad disponible a través del sitio web o cualquiera de sus funcionalidades, ya sea alterando o tratando de alterar, ilegalmente o de cualquier otra forma, el acceso, participación o funcionamiento de aquellos, o falseando el resultado de los mismos y/o utilizando métodos de participación fraudulentos, mediante cualquier procedimiento, y/o mediante cualquier práctica que atente o vulnere de ninguna manera las presente Condiciones de Uso.</li>
              </ul>
              <br> 
              El incumplimiento de cualquiera de las anteriores obligaciones para el usuario podrá llevar implícita la adopción por LA CORREDURIA de las medidas oportunas amparadas en Derecho y en el ejercicio de sus derechos u obligaciones, sin que haya la posibilidad de indemnización por los daños y perjuicios causados. En el caso que encontrara alguna información o contenido en el sitio web que pueda ser no adecuada, contrario a la normativa vigente, o contrario a las condiciones dispuestas en el sitio web, rogamos que lo pongan en conocimiento inmediato de LA CORREDURIA a través de los diferentes medios dispuestos para ello.
            </p> 



            <p class="color h5" style="margin-top: 50px">ENLACES A OTRAS PÁGINAS WEB</p>
            <p> LA CORREDURIA no controla los contenidos, las políticas de privacidad o las prácticas de sitios web de terceros ni asume ninguna responsabilidad por estos.</p>
            <p> El usuario reconoce y acepta que LA CORREDURIA no será responsable de la disponibilidad de los sitios web o recursos externos, y no suscribe ningún tipo de publicidad, productos u otros materiales ofrecidos a través de estos sitios web o recursos. Así como de las pérdidas y daños en que el usuario pueda incurrir a consecuencia de la disponibilidad de los mencionados sitios web o recursos externos, o a consecuencia de la credibilidad que otorgue a la exhaustividad, precisión o existencia de cualquier tipo de publicidad, productos u otros materiales ofrecidos a través de estos sitios web o recursos.</p> 




            <p class="color h5" style="margin-top: 50px">RESPONSABILIDADES Y GARANTÍAS</p>
            <p> En consecuencia, LA CORREDURIA no garantiza ni se hace responsable de las siguientes acciones:</p>
            <ul style="line-height: 30px; margin-left: 40px"> 
              <li>▪ La continuidad de los contenidos del sitio web</li><br>
              <li>▪ La ausencia de errores en estos contenidos</li><br>
              <li>▪ La ausencia de virus u otros componentes nocivos en el sitio web o al servidor que él suministra</li><br>
              <li>▪ La invulnerabilidad del sitio web y/o la imposibilidad de vulnerar las medidas de seguridad que se adopten en el mismo</li><br>
              <li>▪ La falta de utilidad o rendimiento de los contenidos del sitio web</li><br>
              <li>▪ Los daños o perjuicios que cause, así mismo a un tercer, cualquier persona que infringiera las condiciones, normas e instrucciones que LA CORREDURIA establece en el sitio web mediante la vulneración de los sistemas de seguridad del sitio web.</li><br>
            </ul>
            <p>No obstante, LA CORREDURIA declara que ha adoptado todas las medidas necesarias dentro de sus posibilidades y del estado de la técnica, para garantizar el funcionamiento del sitio web y reducir al mínimo los errores del sistema, tanto desde el punto de vista técnico como legal y organizativo. Si el usuario tuviera conocimiento de la existencia de algún contenido ilícito, ilegal, contrario a las leyes o que pudiera suponer una infracción de derechos a terceros, tendrá que notificar inmediatamente a LA CORREDURIA para que esta pueda proceder a la adopción de las medidas oportunas. LA CORREDURIA no será responsable de la veracidad, integridad o actualización de las informaciones publicadas en el sitio web provenientes de fuentes ajenas a éste y no asumirá responsabilidad cuando a hipotéticos perjuicios que pudieran originarse por el uso de las mencionadas informaciones </p>



            <p class="color h5" style="margin-top: 50px">SUSPENSIÓN DEL SITIO WEB</p>
            <p>LA CORREDURIA se reserva el derecho a suspender, modificar, restringir o interrumpir, ya sea temporal o permanentemente, el acceso, navegación uso, alojamiento y/o descarga del contenido y/o uso de servicios del sitio web, con o sin previa notificación, sin que haya la posibilidad que el usuario exija ninguna indemnización por esta causa. </p> 



            <p class="color h5" style="margin-top: 50px">CONFIDENCIALIDAD Y PROTECCIÓN DE DATOS</p>
            <p>De conformidad con lo que dispone la normativa vigente en materia de protección de datos de carácter personal, todos los datos de carácter personal facilitados durante la autorización del sitio web serán tratados de conformidad con lo que dispone la Política de Privacidad que todo usuario tiene de aceptar expresamente para poder utilizar el sitio web. </p> 



            <p class="color h5" style="margin-top: 50px">SPAM</p>
            <p>Queda absolutamente prohibido el SPAM, o cualquier fraude o abuso en nuestra página web. Este tipo de actitudes están penadas por la ley y los propietarios de la web remitirán este tipo de abusos a las autoridades correspondientes. </p> 



            <p class="color h5" style="margin-top: 50px;">JURISDICCIÓN</p>
            <p>Las presentes condiciones generales se regirán por la legislación española. LA CORREDURIA y el USUARIO, con renuncia expresa a cualquier otro fuero que pudiera corresponderles, se someten a la Jurisdicción de los Juzgados y Tribunales de la ciudad de Vic para las cuestiones que puedan surgir o acciones derivadas de la prestación del servicio de este sitio web y de sus servicios y contenidos, y sobre la interpretación, aplicación, cumplimiento o incumplimiento de lo que aquí se establece.</p> 


            <p style="margin-bottom: 100px;"></p>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

<template>
  <div id="privacidad">
    <div class="container-fluid">
      <div class="container">
        <div class="row align-items-center justify-content-center">

          <div class="col-12 text-start">
            <h3 class="color text-center">POLÍTICA DE TRANSPARENCIA</h3>

            <p class="color h5" style="margin-top: 50px">INFORMACIÓN GENERAL. CORREDURÍA DE SEGUROS.</p>

            <p>La actividad profesional de las Corredurías de Seguros se encuentra regulada en el Real Decreto-Ley 3/2020, que traspone la Directiva (UE) 2016/97 sobre la distribución de seguros y demás normativa reglamentaria, garantizando así la protección de los derechos de los clientes. </p>


            <p>Para su conocimiento y a los efectos legalmente previstos, le informamos que esta web es propiedad de la siguiente Correduría de seguros:</p>

            <p> a) Es una sociedad mercantil de nacionalidad española denominada <b> “Confisergur de Gestió Rovira, S.L.”</b> constituida por tiempo indefinido mediante escritura autorizada por el notario de Vic, D. José Vilana Espejo, el día 15 de octubre de 2002, con el número 137837 de su protocolo. Con domicilio social en la Rambla del Carme, 31, 1r, de Vic
            08500 Barcelona. Número de identificación fiscal B-62867387. Inscrita en el Registro Mercantil de Barcelona, folio 0116, tomo 35059, hoja número 259533, inscripción 1ª. </p>

            <p> b) Cumple con todos los requisitos establecidos en la Ley, estando inscrita en el órgano
            supervisor pertinente con el número de clave J399GC, que usted puede comprobar en
            la página web de La Dirección General de Seguros y Fondos de Pensiones <a href="http://www.dgsfp.mineco.es/sector/corredurias.asp" target="_blank">(DGSFP) </a>.</p>

            <p>c) Tiene suscrita póliza de Responsabilidad Civil Profesional y dispone de la capacidad
            financiera legalmente establecida, todo esto en cumplimiento de lo dispuesto en el Real
            Decreto-Ley 3/2020, que transpone la Directiva (UE) 2016/97 sobre la distribución de
            seguros y demás normativa reglamentaria.</p>

            <p> d) Cuenta con un departamento de atención al cliente externo, encargado de atender
            sus quejas y reclamaciones, por correo electrónico a <a href="mailto:josepgranes@icab.cat">josepgranes@icab.cat </a>, y por
            escrito a Servicio de Atención al Cliente, c/ Aragón 208 piso 6, puerta 5 de 08011
            Barcelona. Para mayor información puede dirigirse a cualquier empleado de LA
            CORREDURIA, todo esto de conformidad con lo dispuesto en la Orden del Ministerio de
            Economía ECO/734/2004 de 11 de marzo </p>

            <p> e) Tiene establecido el tratamiento de sus datos de carácter personal, de conformidad con la normativa vigente en materia de Protección de Datos de Carácter Personal.</p>




            <p class="color h5" style="margin-top: 50px">INFORMACIÓN PREVIA. MEDIACIÓN EN SEGUROS.</p>

            <p>De conformidad con el Real Decreto-Ley 3/2020, que transpone la Directiva (UE)
            2016/97 sobre la distribución de seguros y demás normativa reglamentaria, ( en nuestra
            calidad de Correduría de Seguros ), manifestamos que las oferta efectuadas y
            presentadas, base de las pólizas de referencia y/o de la/s posible/s modificación/es, son
            consecuencia de nuestro asesoramiento independiente, a partir del análisis objetivo de
            seguros del mismo sector efectuado en diferentes entidades aseguradoras, y que es,
            según nuestro criterio profesional, la que mejor se adapta a las necesidades
            manifestadas y a su elección definitiva, teniendo en cuenta, tanto la información
            aportada, como nuestra experiencia, y con una aseguradora especializada en este tipo
            de seguros. </p> 


            <p>A consecuencia de lo que dispone el Real Decreto-Ley 3/2020, que transpone la
            Directiva (UE) 2016/97 sobre la distribución de seguros y demás normativa
            reglamentaria, el Tomador autoriza a LA CORREDURIA a analizar las coberturas y a
            solicitar en su nombre un nuevo contrato sobre el riesgo asegurado, en el caso que por
            cualquier circunstancia, el que se suscribiera fuese anulado unilateralmente por la
            Aseguradora, y también autorizando a LA CORREDURIA a realizarlo en caso de mejora
            en precio y/o coberturas. </p>

            <p> <b> “Confisergur de Gestió Rovira, S.L.”</b>  le informa igualmente del contenido del Art. 21
            de la Ley 50/1980, de Contratos de Seguros en virtud del cual, las comunicaciones de
            la correduría a la aseguradora en su nombre tendrán los mismos efectos que si las
            realizara usted (Tomador del seguro).</p>


            <p style="text-decoration: underline;"> A continuación, reflejamos los aspectos más importantes de nuestra actividad: </p>
            <ul style="line-height: 30px; margin-left: 40px; list-style: none;"> 
                <li>1.Los <b> criterios</b> que utilizamos para la selección y comparación de los productos
                de las entidades aseguradoras son los siguientes:
                Realizamos un análisis de los seguros presentes en el mercado, comparando
                sus coberturas y exclusiones, el conjunto de sus condiciones, así como las
                primas de seguro, seleccionando aquellas que proporcionan los contratos más
                adecuados para cubrir las necesidades e intereses de nuestros clientes.
                A la hora de realizar ese análisis, valoramos los procesos internos de las
                entidades aseguradoras, tanto a la hora de la contratación como de la gestión
                del contrato, prestando especial atención a los procedimientos que tienen en
                marcha para la gestión de siniestros (valoración, profesionalidad, tiempos de
                respuesta, liquidaciones económicas, ...)
                </li>
                
                <li> 2. La <b>relación</b> con las entidades aseguradoras derivadas de la actividad, se rigen
                por pactos que acordamos libremente sin que los mismos puedan afectar a
                nuestra independencia como corredores. Los contratos mercantiles que
                firmamos con las entidades aseguradoras se denominan “Cartas de
                Condiciones”.
                Las entidades aseguradoras con las que trabajamos y los productos que ofrecen
                son prácticamente todas, siendo las principales: Liberty Seguros, Allianz, Reale,
                Axa, Arag, Generali, Asefa, Agrupació, Hiscox, Fiatc, Markel, Asisa,
                SegurcaixaAdeslas, etc
                </li>

                <li> 3. Nuestra relación con las entidades aseguradoras mencionadas es remunerada,
                y consiste en una comisión sobre la prima/s contratada. <br>
                El precio del seguro o prima que le ofrecemos al final del proceso de
                asesoramiento está garantizado por la entidad aseguradora en los términos
                contemplados en el art. 6 de la Ley de Contratos de Seguros, por lo tanto, durante
                quince días permanecerá como proposición u oferta de seguro. <br>
                Nosotros, como mediadores no le garantizamos directamente la prima ofertada</li>

                <li> 4. Revisamos y actualizamos esta información con una periodicidad mínima anual.</li>

                <li> 5. Diseñamos coberturas específicas para colectivos con necesidades similares y
                negociamos condiciones de cobertura y precio. En estos casos se realiza un
                análisis previo de las preocupaciones y peticiones, a nivel asegurador de dichos
                colectivos y seleccionamos, de forma totalmente objetiva, entre todas las
                aseguradoras la que más encaja con el producto diseñado. </li>
            </ul>

            <p style="margin-bottom: 100px;"></p>

          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
} 
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

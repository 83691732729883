<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-white">
        <div class="container-fluid">
            <router-link to="/" class="navbar-brand fs-1">
                <img src="../assets/img/conficaravan_logo.png" class="img-fluid" style="height: 60px">
            </router-link>
            <div class="text-end">
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
            </div>
            <div class="collapse navbar-collapse flex-row-reverse" id="navbarSupportedContent" v-if="this.$route.path != '/presupuesto_seguro'">
                <ul class="navbar-nav mb-2 mb-lg-0 fs-5">
                    <li class="nav-item">
                        <router-link to="/" class="nav-link">Inicio</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/coberturas" class="nav-link">Coberturas</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/faq" class="nav-link">Preguntas frecuentes</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/asistencia" class="nav-link">Asistencia</router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>

export default {
  name: 'Header',
//   props: {
//     msg: String
//   }
}
</script>
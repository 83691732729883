<template>
  <div id="contratar">

    <!-- MODAL MENORES -->
    <b-modal id="alert_menores" centered size="lg" :hide-footer="true" :hide-header="true">
      
      <div class="text-center">
        <p><font-awesome-icon icon="exclamation-triangle" class="color" /></p>
        <p>Es obligatorio indicar los conductores menores de 25 años y/o con menos de 2 años de antigüedad del carnet de conducir</p>
        <p class="text-center">
          <button type="button" class="btn btn-primary" @click="$bvModal.hide('alert_menores');">Continuar</button>
        </p>
      </div>
      
    </b-modal>
    <!-- End Modal -->
    <!-- MODAL MENORES -->
    <b-modal id="alert_25" centered size="lg" :hide-footer="true" :hide-header="true">
      
      <div class="text-center">
        <p><font-awesome-icon icon="exclamation-triangle" class="color" /></p>
        <p>Los propietarios de un vehículo menores de 25 años han de ser el Tomador y Conductor principal por defecto.</p>
        <p>Si sigues adelante cambiaremos los datos del Tomador y Conductor principal por los datos del Propietario.</p>
        <p>¿Quieres continuar?</p>
        <p class="text-center">
          <button type="button" class="btn btn-primary me-2" @click="formParttwoSpecial()">Continuar</button>
          <button type="button" class="btn btn-primary" @click="$bvModal.hide('alert_25');">Cancelar</button>
        </p>
      </div>
      
    </b-modal>
    <!-- End Modal -->
    <!-- MODAL CARNET -->
    <b-modal id="carnet" centered size="lg" :hide-footer="true" :hide-header="true">
      
      <div class="text-center">
        <img src="../assets/img/muestra_carnet.png" class="img-fluid">
        <p>Encontrarás la fecha en el reverso del carnet de conducir.</p>        
      </div>
      
    </b-modal>
    <!-- End Modal -->
    <!-- MODAL VALOR VEHICULO -->
    <b-modal id="valorvehiculo" centered size="lg" :hide-footer="true" :hide-header="true">
      <div class="text-center">
        <h3 class="color">Valor vehículo</h3>
        <p class="mt-3">El valor de compra de tu vehículo</p>        
      </div>
    </b-modal>

    <b-modal id="matriculavehiculo" centered size="lg" :hide-footer="true" :hide-header="true">
      <div class="text-center">
        <h3 class="color">Matrícula vehículo</h3>
        <p class="mt-3">La matricula de el vehículo debe tener 4 digitos y 3 caracteres (A0000BC o 0000ABC)</p>        
      </div>
    </b-modal>
    <!-- End Modal -->
    <!-- MODAL VALOR ACCESORIOS -->
    <b-modal id="valoraccesorios" centered size="lg" :hide-footer="true" :hide-header="true">
      
      <div class="text-center">
        <h3 class="color">Valor accesorios</h3>
        <p class="mt-3">El valor de los accesorios fijos incorporados en el vehículo después de su salida de fábrica</p>        
      </div>
      
    </b-modal>
    <!-- End Modal -->
    

    <b-modal id="DniNie" centered size="lg" :hide-footer="true" :hide-header="true">
      <div class="text-left">
        <h3 class="color">Formato DNI/NIE</h3>
        <p class="mt-3">
          <b>El DNI</b> debe escribirse completo, con los ceros iniciales y la letra final, <b>sin espacios ni guiones.</b> Ejemplo 00523821F y no 523821-F. <br><br>
          <b>El NIE</b> (Número de Identificación de Extranjeros) debe escribirse con la X o T inicial, todos los números y la letra final, sin espacios ni guiones. Ejemplo: X0523821F.</p>        
      </div>
    </b-modal>



    <div class="container-fluid">
      <div class="row align-items-top justify-content-center">
          <div class="col-12 bk_contratar p-4 text-center">
      
              <h1 class="text-white mt-3">Presupuesto Seguro Autocaravanas y/o Camper</h1>
              <p class="text-white">Envíanos tus datos y te facilitaremos el presupuesto de seguro personalizado para tu Autocaravana o Camper.</p>

          </div>
      </div>
      <div class="container">
        <div class="row align-items-start justify-content-center mb-4">

          <div class="col-sm-12 col-md-9 mt-4">
            <div class="row align-items-start justify-content-center mt-4 mb-4">
              
              <div class="col-12 col-sm-12 bk_gray rounded pb-3">

                <!-- Inicio Form -->
                
                <form class="p-3">

                  <!-- Datos vehículo -->

                  <div class="row align-items-start justify-content-center alldivs" id="vehiculo">

                    <div class="col-sm-12 mt-3 text-center">
                      <h4 class="color"><img src="../assets/img/form_1_1.png" class="img-fluid"></h4>
                    </div>
                    <div class="col-sm-12 mt-3">
                      <h4 class="color">Datos del vehículo</h4>
                    </div>
                    <div class="col-12 col-sm-6 mt-2">
                      <label for="tipo_auto" class="form-label">Tipo de vehículo <span class="color">*</span></label>
                      <select class="form-select" id="tipo_auto" v-model="seguro.vehiculo.tipo_auto" v-bind:class="{'error-border' : errors.includes('vehiculo_tipo_auto')}" @change="addErrorsVehiculo()">
                          <option value="1" selected >Camper</option>
                          <option value="2" >Autocaravana</option>
                      </select>
                    </div>
                    <div class="col-12 col-sm-6 mt-2">
                      <label for="potencia" class="form-label">Potencia <span class="color">*</span></label>
                      <select class="form-select" id="potencia" v-model="seguro.vehiculo.potencia" v-bind:class="{'error-border' : errors.includes('vehiculo_potencia')}" @change="addErrorsVehiculo()">
                          <option value="1" selected >HASTA 120 CV</option>
                          <option value="2">HASTA 140 CV</option>
                          <option value="3">HASTA 160 CV</option>
                          <option value="4">MÁS DE 160 CV</option>
                      </select>
                    </div>

                    <div class="col-12 col-sm-6 mt-2">
                      <label for="marca" class="form-label">Marca <span class="color">*</span></label>
                      <input type="text" class="form-control" id="marca" autocomplete="nop" v-model="seguro.vehiculo.marca" v-bind:class="{'error-border' : errors.includes('vehiculo_marca')}" @change="addErrorsVehiculo()">
                    </div>
                    <div class="col-12 col-sm-6 mt-2">
                      <label for="modelo" class="form-label">Modelo <span class="color">*</span></label>
                      <input type="text" class="form-control" id="modelo" autocomplete="nop" v-model="seguro.vehiculo.modelo" v-bind:class="{'error-border' : errors.includes('vehiculo_modelo')}" @change="addErrorsVehiculo()">
                    </div>

                    <div class="col-12 col-sm-6 mt-2">
                      <label for="matricula" class="form-label">Matrícula <font-awesome-icon icon="info-circle" class="color hover_icon" data-bs-toggle="button" v-b-modal.matriculavehiculo /></label>
                      <input type="text" class="form-control" id="matricula" v-model="seguro.vehiculo.matricula" >
                    </div>
                    <div class="col-12 col-sm-6 mt-2">
                      <label for="fecha_matriculacion" class="form-label">Año de matriculación <span class="color">*</span></label>
                      <input type="number" class="form-control" id="fecha_matriculacion" v-model="seguro.vehiculo.fecha_matriculacion"  v-bind:class="{'error-border' : errors.includes('vehiculo_fecha_matriculacion')}" @change="addErrorsVehiculo()">
                    </div>
                    
                    <div class="col-12 col-sm-4 mt-2">
                      <label for="valor_autocaravana" class="form-label">Valor vehículo <font-awesome-icon icon="info-circle" class="color hover_icon" data-bs-toggle="button" v-b-modal.valorvehiculo /> <span class="color">*</span></label>
                      <input type="number" class="form-control" id="valor_autocaravana" v-model="seguro.vehiculo.valor_autocaravana"  v-bind:class="{'error-border' : errors.includes('vehiculo_valor_autocaravana')}" @change="addErrorsVehiculo()">
                    </div>
                    <div class="col-12 col-sm-4 mt-2">
                      <label for="valor_accesorios" class="form-label">Valor accesorios <font-awesome-icon icon="info-circle" class="color hover_icon" data-bs-toggle="button" v-b-modal.valoraccesorios /> <span class="color">*</span></label>
                      <input type="number" class="form-control" id="valor_accesorios" v-model="seguro.vehiculo.valor_accesorios"  v-bind:class="{'error-border' : errors.includes('vehiculo_valor_accesorios')}" @change="addErrorsVehiculo()">
                    </div>
                    <div class="col-12 col-sm-4 mt-2">
                      <label for="valor_total" class="form-label">Valor total del vehículo</label>
                      <input type="number" class="form-control" id="valor_total" v-model="seguro.vehiculo.valor_total" :placeholder="Math.abs(Number(seguro.vehiculo.valor_autocaravana) + Number(seguro.vehiculo.valor_accesorios))" disabled>
                    </div>


                    <div class="col-12 mt-4 mb-4 text-center">
                      <button type="button" class="btn btn-primary" @click="formPartOne()">Paso siguiente</button>
                    </div>
                    <div class="col-12 text-center" v-if="this.$v.seguro.vehiculo.$invalid">
                      <div class="error" v-if="submitted && !$v.seguro.vehiculo.required">Faltan campos por completar</div>
                      <div class="error" v-if="submitted && !$v.seguro.vehiculo.fecha_matriculacion.year">El campo año de matriculación no es correcto</div>
                    </div>
                    
                  </div>

                  <!-- END Datos vehiculo -->
                  <!-- Datos Tomador -->

                  <div class="col-12 alldivs d-none" id="tomador">

                    <div class="col-sm-12 mt-3 text-center">
                      <h4 class="color"><img src="../assets/img/form_1_2.png" class="img-fluid"></h4>
                    </div>

                    <div class="col-12 text-end">
                      <a type="button" class="color ps-3" @click="rear('tomador','vehiculo')">&lt;&lt; Atrás</a>
                    </div>

                    <div class="row align-items-center justify-content-center">
                      <div class="col-12 p-4 pb-0">
                        <div class="row align-items-center justify-content-center">
                          <div class="col-12 bg-white rounded p-4">
                            <div class="row align-items-center justify-content-center">
                              <div class="col-12 col-sm-8">
                                <p class="mb-0">¿El tomador es una empresa? <span class="color">*</span></p>
                              </div>
                              <div class="col-12 col-sm-4">
                                <div class="form-check form-check-inline">
                                  <input class="form-check-input" type="radio" name="radio_empresa" id="radio_empresa1" value="1" v-model="radio_empresa" @change="empresaToggle('empresa_div','tomador_div')">
                                  <label class="form-check-label" for="radio_empresa">Sí</label>
                                </div>
                                <div class="form-check form-check-inline">
                                  <input class="form-check-input" type="radio" name="radio_empresa" id="radio_empresa2" value="0" v-model="radio_empresa" @change="empresaToggle('empresa_div','tomador_div')">
                                  <label class="form-check-label" for="radio_empresa">No</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Datos Empresa -->
                    
                    <div class="row align-items-start justify-content-center mt-2 d-none" id="empresa_div">

                      <div class="col-sm-12 mt-3">
                        <h4 class="color">Datos del tomador empresa</h4>
                      </div>

                      <div class="col-12 col-sm-8 mt-2">
                        <label for="name_empresa" class="form-label">Nombre empresa <span class="color">*</span></label>
                        <input type="text" class="form-control" id="nombre_empresa" v-model="seguro.empresa.tomador_empresa.nombre"  v-bind:class="{'error-border' : errors.includes('empresa_tomador_empresa_nombre')}" @change="addErrorsSecondPart()">
                      </div>
                      <div class="col-12 col-sm-4 mt-2">
                        <label for="empresa_type" class="form-label">Identificación <font-awesome-icon icon="info-circle" class="color hover_icon" data-bs-toggle="button" v-b-modal.DniNie/> <span class="color">*</span></label>
                        <div class="row align-items-center justify-content-center">
                          <div class="col-5">
                            <select class="form-select" aria-label="empresa_type" disabled>
                              <option value="cif" selected >CIF</option>
                            </select>
                          </div>
                          <div class="col-7">
                            <input type="text" class="form-control" id="empresa_cif" v-model="seguro.empresa.tomador_empresa.cif" v-bind:class="{'error-border' : errors.includes('empresa_tomador_empresa_cif')}" @change="addErrorsSecondPart()" >
                          </div>
                        </div>
                      </div>
                      
                      <div class="col-12 col-sm-12">
                        <div class="row align-items-center justify-content-center">

                          <div class="col-12 col-sm-3  mt-2">
                            <label for="empresa_tipo_de_via" class="form-label">Tipo de vía <span class="color">*</span></label>
                            <select class="form-select" v-model="seguro.empresa.tomador_empresa.tipo_de_via"  id="empresa_tipo_de_via"   v-bind:class="{'error-border' : errors.includes('empresa_tomador_empresa_tipo_de_via')}" @change="addErrorsSecondPart()">
                              <option value="Avenida" selected >Avenida</option>
                              <option value="Calle" selected >Calle</option>
                              <option value="Lugar" selected >Lugar</option>
                              <option value="Pasaje" selected >Pasaje</option>
                              <option value="Paseo" selected >Paseo</option>
                              <option value="Plaza" selected >Plaza</option>
                              <option value="Rambla" selected >Rambla</option>
                              <option value="Ronda" selected >Ronda</option>
                            </select>

                          </div>

                          <div class="col-12 col-sm-3  mt-2">
                            <label for="empresa_direccion_texto" class="form-label">Dirección <span class="color">*</span></label>
                            <input type="text" class="form-control" id="empresa_direccion_texto" v-model="seguro.empresa.tomador_empresa.direccion_texto"    v-bind:class="{'error-border' : errors.includes('empresa_tomador_empresa_direccion_texto')}" @change="addErrorsSecondPart()">
                          </div>
                          <div class="col-12 col-sm-2  mt-2">
                            <label for="empresa_direccion_numero" class="form-label">Número</label>
                            <input type="text" class="form-control" id="empresa_direccion_numero" autocomplete="nop" v-model="seguro.empresa.tomador_empresa.direccion_numero" >
                          </div>
                          <div class="col-12 col-sm-2 mt-2">
                            <label for="empresa_direccion_piso" class="form-label">Piso</label>
                            <input type="text" class="form-control" id="empresa_direccion_piso" autocomplete="nop" v-model="seguro.empresa.tomador_empresa.direccion_piso" >
                          </div>
                          <div class="col-12 col-sm-2 mt-2">
                            <label for="empresa_direccion_puerta" class="form-label">Puerta</label>
                            <input type="text" class="form-control" id="empresa_direccion_puerta" autocomplete="nop" v-model="seguro.empresa.tomador_empresa.direccion_puerta" >
                          </div>
                        </div>

                      </div>
                      
                      <div class="col-12 col-sm-6 mt-2">
                        <label for="empresa_postal" class="form-label">Código postal <span class="color">*</span></label>
                        <input type="text" class="form-control" id="empresa_postal" v-model="seguro.empresa.tomador_empresa.postal" v-bind:class="{'error-border' : errors.includes('empresa_tomador_empresa_postal')}" @change="addErrorsSecondPart()">
                      </div>
                      <div class="col-12 col-sm-6 mt-2">
                        <label for="empresa_localidad" class="form-label">Localidad <span class="color">*</span></label>
                        <input type="text" class="form-control" id="empresa_localidad" v-model="seguro.empresa.tomador_empresa.localidad" v-bind:class="{'error-border' : errors.includes('empresa_tomador_empresa_localidad')}" @change="addErrorsSecondPart()">
                      </div>
                      <div class="col-12 col-sm-6 mt-2">
                        <label for="empresa_telefono" class="form-label">Teléfono <span class="color">*</span></label>
                        <input type="text" class="form-control" id="empresa_telefono" v-model="seguro.empresa.tomador_empresa.telefono" v-bind:class="{'error-border' : errors.includes('empresa_tomador_empresa_telefono')}" @change="addErrorsSecondPart()">
                      </div>
                      <div class="col-12 col-sm-6 mt-2">
                        <label for="empresa_email" class="form-label">Email <span class="color">*</span></label>
                        <input type="text" class="form-control" id="empresa_email" v-model="seguro.empresa.tomador_empresa.email" v-bind:class="{'error-border' : errors.includes('empresa_tomador_empresa_email')}" >
                      </div>

                      <div class="col-sm-12 mt-3">
                        <h4 class="color">Datos conductor principal</h4>
                      </div>

                      <div class="col-12 col-sm-4 mt-2">
                        <label for="empresa_nombre_pral" class="form-label">Nombre <span class="color">*</span></label>
                        <input type="text" class="form-control" id="empresa_nombre_pral" v-model="seguro.empresa.conductor_pral.nombre"   v-bind:class="{'error-border' : errors.includes('empresa_conductor_pral_nombre')}" @change="addErrorsSecondPart()">
                      </div>
                      <div class="col-12 col-sm-4 mt-2">
                        <label for="empresa_apellido_1" class="form-label">Primer apellido <span class="color">*</span></label>
                        <input type="text" class="form-control" id="empresa_apellido_1" autocomplete="nop" v-model="seguro.empresa.conductor_pral.apellido_1"   v-bind:class="{'error-border' : errors.includes('empresa_conductor_pral_aprellido_1')}" @change="addErrorsSecondPart()">
                      </div>
                      <div class="col-12 col-sm-4 mt-2">
                        <label for="empresa_apellido_2" class="form-label">Segundo apellido</label>
                        <input type="text" class="form-control" id="empresa_apellido_2" autocomplete="nop" v-model="seguro.empresa.conductor_pral.apellido_2" >
                      </div>
                      <div class="col-12 col-sm-4 mt-2">
                        <label for="empresa_dni" class="form-label">Identificación <font-awesome-icon icon="info-circle" class="color hover_icon" data-bs-toggle="button" v-b-modal.DniNie/><span class="color">*</span></label>
                        <div class="row align-items-center justify-content-center">
                          <div class="col-5">
                            <select class="form-select" aria-label="empresa_dni" v-model="seguro.empresa.conductor_pral.dni_tipo" >
                              <option selected>Selecciona tipo de documento</option>
                              <option value="dni">DNI</option>
                              <option value="nie">NIE</option>
                            </select>
                          </div>
                          <div class="col-7">
                            <input type="text" class="form-control" id="empresa_dni" v-model="seguro.empresa.conductor_pral.dni" v-bind:class="{'error-border' : errors.includes('empresa_conductor_pral_dni')}" @change="addErrorsSecondPart()">
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-sm-4 mt-2">
                        <label for="empresa_nacimiento" class="form-label">Fecha nacimiento <span class="color">*</span></label>
                        <input type="date" class="form-control" id="empresa_nacimiento" v-model="seguro.empresa.conductor_pral.nacimiento"  v-bind:class="{'error-border' : errors.includes('empresa_conductor_pral_nacimiento')}"  @change="addErrorsSecondPart()">
                      </div>
                      <div class="col-12 col-sm-4 mt-2">
                        <label for="empresa_carnet" class="form-label">Fecha 1ª expedición carnet conducir <font-awesome-icon icon="info-circle" class="color hover_icon" data-bs-toggle="button" v-b-modal.carnet/> <span class="color">*</span></label>
                        <input type="date" class="form-control" id="empresa_carnet" v-model="seguro.empresa.conductor_pral.carnet" :max="maxDate" v-bind:class="{'error-border' : errors.includes('empresa_conductor_pral_carnet')}" @change="addErrorsSecondPart()" >
                      </div>
                    </div>

                    <!-- END Datos Empresa -->
                    <!-- Datos tomador_div -->

                    <div class="row align-items-start justify-content-center mt-2" id="tomador_div">
                      <div class="col-sm-12 mt-3">
                        <h4 class="color">Datos del tomador</h4>
                      </div>
                      <div class="col-12 col-sm-4 mt-2">
                        <label for="tomador_nombre" class="form-label">Nombre <span class="color">*</span></label>
                        <input type="text" class="form-control" id="tomador_nombre" v-model="seguro.tomador.nombre" v-bind:class="{'error-border' : errors.includes('tomador_nombre')}" @change="addErrorsSecondPart()">
                      </div>
                      <div class="col-12 col-sm-4 mt-2">
                        <label for="tomador_apellido_1" class="form-label">Primer apellido <span class="color">*</span></label>
                        <input type="text" class="form-control" id="tomador_apellido_1" autocomplete="nop" v-model="seguro.tomador.apellido_1" v-bind:class="{'error-border' : errors.includes('tomador_apellido_1')}" @change="addErrorsSecondPart()">
                      </div>
                      <div class="col-12 col-sm-4 mt-2">
                        <label for="tomador_apellido_2" class="form-label">Segundo apellido</label>
                        <input type="text" class="form-control" id="tomador_apellido_2" autocomplete="nop" v-model="seguro.tomador.apellido_2" v-bind:class="{'error-border' : errors.includes('tomador_apellido_2')}" @change="addErrorsSecondPart()">
                      </div>
                      <div class="col-12 col-sm-4 mt-2">
                        <label for="tomador_dni" class="form-label">Identificación <font-awesome-icon icon="info-circle" class="color hover_icon" data-bs-toggle="button" v-b-modal.DniNie/> <span class="color">*</span></label>
                        <div class="row align-items-center justify-content-center">
                          <div class="col-5">
                            <select class="form-select" aria-label="tomador_dni" v-model="seguro.tomador.dni_tipo"  v-bind:class="{'error-border' : errors.includes('tomador_dni_tipo')}" @change="addErrorsSecondPart()" >
                              <option selected>Selecciona tipo de documento</option>
                              <option value="dni">DNI</option>
                              <option value="nie">NIE</option>
                            </select>
                          </div>
                          <div class="col-7">
                            <input type="text" class="form-control" id="tomador_dni" v-model="seguro.tomador.dni" v-bind:class="{'error-border' : errors.includes('tomador_dni')}" @change="addErrorsSecondPart()">
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-sm-4 mt-2">
                        <label for="tomador_nacimiento" class="form-label">Fecha nacimiento <span class="color">*</span></label>
                        <input type="date" class="form-control" id="tomador_nacimiento" v-model="seguro.tomador.nacimiento" v-bind:class="{'error-border' : errors.includes('tomador_nacimiento')}" @change="addErrorsSecondPart()" >
                      </div>
                      <div class="col-12 col-sm-4 mt-2">
                        <label for="tomador_carnet" class="form-label">Fecha 1º expedición carnet conducir <font-awesome-icon icon="info-circle" class="color hover_icon" data-bs-toggle="button" v-b-modal.carnet/> <span class="color">*</span></label>
                        <input type="date" class="form-control" id="tomador_carnet" v-model="seguro.tomador.carnet" :max="maxDate" v-bind:class="{'error-border' : errors.includes('tomador_carnet')}" @change="addErrorsSecondPart()">
                      </div>
                      <div class="col-12 col-sm-12">
                        <div class="row align-items-center justify-content-center">
                          <div class="col-12 col-sm-3  mt-2">
                            <label for="tomador_tipo_de_via" class="form-label">Tipo de vía <span class="color">*</span></label>
                            <select class="form-select"  v-model="seguro.tomador.tipo_de_via" id="tomador_tipo_de_via"   v-bind:class="{'error-border' : errors.includes('tomador_tipo_de_via')}" @change="addErrorsSecondPart()">
                              <option value="Avenida" selected >Avenida</option>
                              <option value="Calle" selected >Calle</option>
                              <option value="Lugar" selected >Lugar</option>
                              <option value="Pasaje" selected >Pasaje</option>
                              <option value="Paseo" selected >Paseo</option>
                              <option value="Plaza" selected >Plaza</option>
                              <option value="Rambla" selected >Rambla</option>
                              <option value="Ronda" selected >Ronda</option>
                            </select>
                          </div>

                          <div class="col-12 col-sm-3  mt-2">
                            <label for="tomador_direccion_texto" class="form-label">Dirección <span class="color">*</span></label>
                            <input type="text" class="form-control" id="tomador_direccion_texto" v-model="seguro.tomador.direccion_texto"  v-bind:class="{'error-border' : errors.includes('tomador_direccion_texto')}" @change="addErrorsSecondPart()" >
                          </div>
                          <div class="col-12 col-sm-2  mt-2">
                            <label for="tomador_direccion_numero" class="form-label">Número</label>
                            <input type="text" class="form-control" id="tomador_direccion_numero" autocomplete="nop" v-model="seguro.tomador.direccion_numero" >
                          </div>
                          <div class="col-12 col-sm-2 mt-2">
                            <label for="tomador_direccion_piso" class="form-label">Piso</label>
                            <input type="text" class="form-control" id="tomador_direccion_piso" autocomplete="nop" v-model="seguro.tomador.direccion_piso" >
                          </div>
                          <div class="col-12 col-sm-2 mt-2">
                            <label for="tomador_direccion_puerta" class="form-label">Puerta</label>
                            <input type="text" class="form-control" id="tomador_direccion_puerta" autocomplete="nop" v-model="seguro.tomador.direccion_puerta" >
                          </div>
                        </div>
                      </div>
                      
                      <div class="col-12 col-sm-6 mt-2">
                        <label for="tomador_postal" class="form-label">Código postal <span class="color">*</span></label>
                        <input type="text" class="form-control" id="tomador_postal" v-model="seguro.tomador.postal"  v-bind:class="{'error-border' : errors.includes('tomador_postal')}" @change="addErrorsSecondPart()">
                      </div>
                      <div class="col-12 col-sm-6 mt-2">
                        <label for="tomador_localidad" class="form-label">Localidad <span class="color">*</span></label>
                        <input type="text" class="form-control" id="tomador_localidad" v-model="seguro.tomador.localidad"  v-bind:class="{'error-border' : errors.includes('tomador_localidad')}" @change="addErrorsSecondPart()">
                      </div>
                      <div class="col-12 col-sm-6 mt-2">
                        <label for="tomador_telefono" class="form-label">Teléfono <span class="color">*</span></label>
                        <input type="text" class="form-control" id="tomador_telefono" v-model="seguro.tomador.telefono"  v-bind:class="{'error-border' : errors.includes('tomador_telefono')}" @change="addErrorsSecondPart()">
                      </div>
                      <div class="col-12 col-sm-6 mt-2">
                        <label for="tomador_email" class="form-label">Email <span class="color">*</span></label>
                        <input type="text" class="form-control" id="tomador_email" v-model="seguro.tomador.email"  v-bind:class="{'error-border' : errors.includes('tomador_email')}" @change="addErrorsSecondPart()">
                      </div>
                    </div>


                    <!-- CONOCIDO -->
                    <div class="row" style="margin-bottom: 10px; text-align: left;">
                        <div class="col-12 col-sm-6 mt-2" >
                        <label for="potencia" class="form-label">¿Cómo nos has conocido?</label>
                        <select type="text" @change="comprovarValueConocido()" class="form-select como_conocido" id="como_conocido" placeholder="email@ejemplo.com" aria-label="Email" aria-describedby="Email" v-model="seguro.como_conocido">
                            <option value="1"> Internet </option>
                            <option value="2"> Redes Sociales </option>
                            <option value="3"> Amigo/a </option>
                            <option value="4"> Otros </option>
                        </select>
                        </div>

                        <div class="col-12 col-sm-6 mt-2" v-if="(amagarInputOtros == false)" style="margin-bottom: 10px; text-align: left;">
                        <label for="" class="form-label">Otros..</label>
                        <input type="text" id="como_conocido_otros" class="form-control" placeholder="Otros" aria-label="Email" aria-describedby="Email" v-model="seguro.como_conocido_otros">
                        </div>
                    </div>

                    <!-- CODIGO PROMOCIONAL -->
                    <div class="row" style="margin-bottom: 20px">
                        <div class="col-12" style="margin-bottom: 10px; text-align: left">
                        <label for="potencia" class="form-label">¿Tienes un código promocional?</label> <br>
                        <div class="row" style="display: flex;align-items: center; height: 40px">
                            <div class="col-2">
                                <!-- <input type="checkbox" class="form-check-input" id="tiene_codigo_promocional" name="tiene_codigo_promocional" style="text-align: center" @change="comprovarCodigoPromocional()" v-model="seguro.tiene_codigo_promocional"> -->
                                <div class="row">
                                    <div class="col-md-6">
                                        <input class="form-check-input" type="radio" name="tiene_codigo_promocional" id="tiene_codigo_promocional" value="1" v-model="seguro.tiene_codigo_promocional" @change="comprovarCodigoPromocional()"><label class="form-check-label" for="tiene_codigo_promocional">Sí</label>
                                    </div>
                                    <div class="col-md-6">
                                        <input class="form-check-input" type="radio" name="tiene_codigo_promocional" id="tiene_codigo_promocional" value="0" v-model="seguro.tiene_codigo_promocional" @change="comprovarCodigoPromocional()"><label class="form-check-label" for="tiene_codigo_promocional">No</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-5">
                                <input type="text" v-if="(amagarInputCodigo == false)" id="codigo_promocional" class="form-control" placeholder="Introducir codigo" aria-label="CODIGO" aria-describedby="CODIGO" v-model="seguro.codigo_promocional">
                            </div>
                        </div>
                        </div>
                    </div>

                    <!-- Datos tomador_div -->
                    <!-- Datos Antecedentes -->

                    <div class="col-12 bg-white rounded mt-4 p-4">
                      <div class="row align-items-center justify-content-center">
                        <div class="col-12 col-sm-8">
                          <p class="mb-0">¿El tomador tiene contratada otra póliza de auto?<br>Indícanos tus datos para que tu precio sea bonificado. <span class="color">*</span></p>
                        </div>
                        <div class="col-12 col-sm-4">
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="radio_antecedentes" id="radio_antecedentes1" value="1" v-model="radio_antecedentes" @change="nextMiniDiv('antecedentes')">
                            <label class="form-check-label" for="radio_antecedentes">Sí</label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="radio_antecedentes" id="radio_antecedentes2" value="0" v-model="radio_antecedentes" @change="nextMiniDiv('antecedentes')">
                            <label class="form-check-label" for="radio_antecedentes">No</label>
                          </div>
                        </div>
                      </div>

                      <div class="row align-items-start justify-content-center mt-2 d-none" id="antecedentes">

                        <div class="col-12 col-sm-4 mt-2">
                          <label for="compania" class="form-label">Compañía actual <span class="color">*</span></label>
                          <input type="text" class="form-control" id="compania" v-model="seguro.antecedentes.compania"  v-bind:class="{'error-border' : errors.includes('antecedentes_compania')}" @change="addErrorsSecondPart()" >
                        </div>
                        <div class="col-12 col-sm-4 mt-2">
                          <label for="poliza_bonificacion" class="form-label">Número de póliza <span class="color">*</span></label>
                          <input type="text" class="form-control" id="poliza" v-model="seguro.antecedentes.poliza"  v-bind:class="{'error-border' : errors.includes('antecedentes_poliza')}" @change="addErrorsSecondPart()" >
                        </div>
                        <div class="col-12 col-sm-4 mt-2">
                          <label for="matricula_bonificacion" class="form-label">Matrícula bonificación <font-awesome-icon icon="info-circle" class="color hover_icon" data-bs-toggle="button" v-b-modal.matriculavehiculo /><span class="color">*</span> </label>
                          <input type="text" class="form-control" id="matricula_bonificacion" v-model="seguro.antecedentes.matricula_bonificacion" v-bind:class="{'error-border' : errors.includes('antecedentes_matricula_bonificacion')}" @change="addErrorsSecondPart()">
                        </div>
                      </div>
                    </div>

                    <!-- END Datos Antecedentes -->
                    <!-- Datos Propietario -->

                    <div class="col-12 bg-white rounded mt-4 p-4">
                      <div class="row align-items-center justify-content-center">
                        <div class="col-12 col-sm-8">
                          <p class="mb-0">¿El Tomador es el propietario del vehículo? <span class="color">*</span></p>
                        </div>
                        <div class="col-12 col-sm-4">
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="radio_propietario" id="radio_propietario1" value="0" v-model="radio_propietario" @change="nextMiniDiv('propietario')">
                            <label class="form-check-label" for="radio_propietario">Sí</label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="radio_propietario" id="radio_propietario2" value="1" v-model="radio_propietario" @change="nextMiniDiv('propietario')">
                            <label class="form-check-label" for="radio_propietario">No</label>
                          </div>
                        </div>
                      </div>

                      <div class="row align-items-start justify-content-center mt-2 d-none" id="propietario">

                        <div class="col-12 col-sm-4 mt-2">
                          <label for="propietario_nombre" class="form-label">Nombre <span class="color">*</span></label>
                          <input type="text" class="form-control" id="propietario_nombre" v-model="seguro.propietario.nombre" >
                        </div>
                        <div class="col-12 col-sm-4 mt-2">
                          <label for="propietario_apellido_1" class="form-label">Primer apellido <span class="color">*</span></label>
                          <input type="text" class="form-control" id="propietario_apellido_1" v-model="seguro.propietario.apellido_1" >
                        </div>
                        <div class="col-12 col-sm-4 mt-2">
                          <label for="propietario_apellido_2" class="form-label">Segundo apellido</label>
                          <input type="text" class="form-control" id="propietario_apellido_2" v-model="seguro.propietario.apellido_2" >
                        </div>
                        <div class="col-12 col-sm-4 mt-2">
                          <label for="propietario_dni" class="form-label">Identificación <font-awesome-icon icon="info-circle" class="color hover_icon" data-bs-toggle="button" v-b-modal.DniNie/> <span class="color">*</span></label>
                          <div class="row align-items-center justify-content-center">
                            <div class="col-5">
                              <select class="form-select" aria-label="propietario_dni" v-model="seguro.propietario.dni_tipo">
                                <option selected>Selecciona tipo de documento</option>
                                <option value="dni">DNI</option>
                                <option value="nie">NIE</option>
                                <option value="cif">CIF</option>
                              </select>
                            </div>
                            <div class="col-7">
                              <input type="text" class="form-control" id="propietario_dni" v-model="seguro.propietario.dni">
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-4 mt-2">
                          <label for="propietario_nacimiento" class="form-label">Fecha nacimiento <span class="color">*</span></label>
                          <input type="date" class="form-control" id="propietario_nacimiento" v-model="seguro.propietario.nacimiento" @change="edad()">
                        </div>
                        <div class="col-12 col-sm-4 mt-2">
                          <label for="propietario_carnet" class="form-label">Fecha 1º expedición carnet conducir <font-awesome-icon icon="info-circle" class="color hover_icon" data-bs-toggle="button" v-b-modal.carnet/> <span class="color">*</span></label>
                          <input type="date" class="form-control" id="propietario_carnet" v-model="seguro.propietario.carnet" :max="maxDate">
                        </div>
                        <div class="col-12 col-sm-12">
                          <div class="row align-items-center justify-content-center">
                            <div class="col-12 col-sm-6  mt-2">
                              <label for="propietario_direccion_texto" class="form-label">Dirección <span class="color">*</span></label>
                              <input type="text" class="form-control" id="propietario_direccion_texto" v-model="seguro.propietario.direccion_texto" >
                            </div>
                            <div class="col-12 col-sm-2  mt-2">
                              <label for="propietario_direccion_numero" class="form-label">Número</label>
                              <input type="number" class="form-control" id="propietario_direccion_numero" v-model="seguro.propietario.direccion_numero" >
                            </div>
                            <div class="col-12 col-sm-2 mt-2">
                              <label for="propietario_direccion_piso" class="form-label">Piso</label>
                              <input type="text" class="form-control" id="propietario_direccion_piso" v-model="seguro.propietario.direccion_piso" >
                            </div>
                            <div class="col-12 col-sm-2 mt-2">
                              <label for="propietario_direccion_puerta" class="form-label">Puerta</label>
                              <input type="text" class="form-control" id="propietario_direccion_puerta" v-model="seguro.propietario.direccion_puerta" >
                            </div>
                          </div>
                        </div>
                        
                        <div class="col-12 col-sm-6 mt-2">
                          <label for="propietario_postal" class="form-label">Código postal <span class="color">*</span></label>
                          <input type="text" class="form-control" id="propietario_postal" v-model="seguro.propietario.postal" >
                        </div>
                        <div class="col-12 col-sm-6 mt-2">
                          <label for="propietario_localidad" class="form-label">Localidad <span class="color">*</span></label>
                          <input type="text" class="form-control" id="propietario_localidad" v-model="seguro.propietario.localidad" >
                        </div>
                        <div class="col-12 col-sm-6 mt-2">
                          <label for="propietario_telefono" class="form-label">Teléfono <span class="color">*</span></label>
                          <input type="text" class="form-control" id="propietario_telefono" v-model="seguro.propietario.telefono" >
                        </div>
                        <div class="col-12 col-sm-6 mt-2">
                          <label for="propietario_email" class="form-label">Email <span class="color">*</span></label>
                          <input type="text" class="form-control" id="propietario_email" v-model="seguro.propietario.email" >
                        </div>
                      </div>

                    </div>

                    <!-- END Datos Propietario -->
                    <!-- Datos Conductor Adicional -->

                    <div class="col-12 bg-white rounded mt-4 p-4">
                      <div class="row align-items-center justify-content-center">
                        <div class="col-12 col-sm-8">
                          <p class="mb-0">¿Quieres añadir otro conductor adicional? <span class="color">*</span></p>
                        </div>
                        <div class="col-12 col-sm-4">
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="radio_conductor_adicional" id="radio_conductor1" value="1" v-model="radio_conductor_adicional_1" @change="nextMiniDiv('conductor_adicional_1')">
                            <label class="form-check-label" for="radio_conductor_adicional">Sí</label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="radio_conductor_adicional" id="radio_conductor2" value="0" v-model="radio_conductor_adicional_1" @change="nextMiniDiv('conductor_adicional_1')">
                            <label class="form-check-label" for="radio_conductor_adicional">No</label>
                          </div>
                        </div>
                      </div>

                      <!-- Datos Conductor_1 -->

                      <div class="row align-items-start justify-content-center mt-2 d-none" id="conductor_adicional_1">

                        <div class="col-12 col-sm-4 mt-2">
                          <label for="conductor1_nombre" class="form-label">Nombre <span class="color">*</span></label>
                          <input type="text" class="form-control" id="conductor1_nombre" v-model="seguro.conductor_1.nombre" v-bind:class="{'error-border' : errors.includes('conductor_1_nombre')}" @change="addErrorsSecondPart()" >
                        </div>
                        <div class="col-12 col-sm-4 mt-2">
                          <label for="conductor1_apellido_1" class="form-label">Primer apellido <span class="color">*</span></label>
                          <input type="text" class="form-control" id="conductor1_apellido_1" v-model="seguro.conductor_1.apellido_1"  v-bind:class="{'error-border' : errors.includes('conductor_1_apellido_1')}" @change="addErrorsSecondPart()" >
                        </div>
                        <div class="col-12 col-sm-4 mt-2">
                          <label for="conductor1_apellido_2" class="form-label">Segundo apellido</label>
                          <input type="text" class="form-control" id="conductor1_apellido_2" v-model="seguro.conductor_1.apellido_2" >
                        </div>
                        <div class="col-12 col-sm-4 mt-2">
                          <label for="conductor1_dni" class="form-label">Identificación <font-awesome-icon icon="info-circle" class="color hover_icon" data-bs-toggle="button" v-b-modal.DniNie/><span class="color">*</span></label>
                          <div class="row align-items-center justify-content-center">
                            <div class="col-5">
                              <select class="form-select" aria-label="conductor1_dni" v-model="seguro.conductor_1.dni_tipo" v-bind:class="{'error-border' : errors.includes('conductor_1_dni_tipo')}" @change="addErrorsSecondPart()">
                                <option selected>Selecciona tipo de documento</option>
                                <option value="dni">DNI</option>
                                <option value="nie">NIE</option>
                              </select>
                            </div>
                            <div class="col-7">
                              <input type="text" class="form-control" id="conductor1_dni" v-model="seguro.conductor_1.dni"  v-bind:class="{'error-border' : errors.includes('conductor_1_dni')}" @change="addErrorsSecondPart()">
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-4 mt-2">
                          <label for="conductor1_nacimiento" class="form-label">Fecha nacimiento <span class="color">*</span></label>
                          <input type="date" class="form-control" id="conductor1_nacimiento" v-model="seguro.conductor_1.nacimiento"  v-bind:class="{'error-border' : errors.includes('conductor_1_nacimiento')}" @change="addErrorsSecondPart()" >
                        </div>
                        <div class="col-12 col-sm-4 mt-2">
                          <label for="conductor1_carnet" class="form-label">Fecha 1º expedición carnet conducir <font-awesome-icon icon="info-circle" class="color hover_icon" data-bs-toggle="button" v-b-modal.carnet/> <span class="color">*</span></label>
                          <input type="date" class="form-control" id="conductor1_carnet" v-model="seguro.conductor_1.carnet" :max="maxDate"  v-bind:class="{'error-border' : errors.includes('conductor_1_carnet')}" @change="addErrorsSecondPart()">
                        </div>
                        <div class="col-12 col-sm-6 mt-4">
                          <p class="mb-0">¿Quieres añadir otro conductor adicional? <span class="color">*</span></p>
                        </div>
                        <div class="col-12 col-sm-6 mt-4">
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="radio_conductor_adicional_2" id="radio_conductor1" value="1" v-model="radio_conductor_adicional_2" @change="nextMiniDiv('conductor_adicional_2')">
                            <label class="form-check-label" for="radio_conductor_adicional">Sí</label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="radio_conductor_adicional_2" id="radio_conductor2" value="0" v-model="radio_conductor_adicional_2" @change="nextMiniDiv('conductor_adicional_2')">
                            <label class="form-check-label" for="radio_conductor_adicional">No</label>
                          </div>
                        </div>
                      </div>

                      <!-- Datos Conductor_2 -->

                      <div class="row align-items-start justify-content-center mt-2 d-none" id="conductor_adicional_2">

                        <div class="col-12 mt-2">
                          <h6 class="color">Segundo conductor adicional</h6>
                        </div>
                        <div class="col-12 col-sm-4 mt-2">
                          <label for="conductor2_nombre" class="form-label">Nombre <span class="color">*</span></label>
                          <input type="text" class="form-control" id="conductor2_nombre" v-model="seguro.conductor_2.nombre"   v-bind:class="{'error-border' : errors.includes('conductor_2_nombre')}" @change="addErrorsSecondPart()">
                        </div>
                        <div class="col-12 col-sm-4 mt-2">
                          <label for="conductor2_apellido_1" class="form-label">Primer apellido <span class="color">*</span></label>
                          <input type="text" class="form-control" id="conductor2_apellido_1" v-model="seguro.conductor_2.apellido_1"  v-bind:class="{'error-border' : errors.includes('conductor_2_apellido_1')}" @change="addErrorsSecondPart()" >
                        </div>
                        <div class="col-12 col-sm-4 mt-2">
                          <label for="conductor2_apellido_2" class="form-label">Segundo apellido</label>
                          <input type="text" class="form-control" id="conductor2_apellido_2" v-model="seguro.conductor_2.apellido_2" >
                        </div>
                        <div class="col-12 col-sm-4 mt-2">
                          <label for="conductor2_dni" class="form-label">Identificación <font-awesome-icon icon="info-circle" class="color hover_icon" data-bs-toggle="button" v-b-modal.DniNie/><span class="color">*</span></label>
                          <div class="row align-items-center justify-content-center">
                            <div class="col-5">
                              <select class="form-select" aria-label="conductor2_dni" v-model="seguro.conductor_2.dni_tipo" v-bind:class="{'error-border' : errors.includes('conductor_2_dni_tipo')}" @change="addErrorsSecondPart()">
                                <option selected>Selecciona tipo de documento</option>
                                <option value="dni">DNI</option>
                                <option value="nie">NIE</option>
                              </select>
                            </div>
                            <div class="col-7">
                              <input type="text" class="form-control" id="conductor2_dni" v-model="seguro.conductor_2.dni"  v-bind:class="{'error-border' : errors.includes('conductor_2_dni')}" @change="addErrorsSecondPart()">
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-4 mt-2">
                          <label for="conductor2_nacimiento" class="form-label">Fecha nacimiento <span class="color">*</span></label>
                          <input type="date" class="form-control" id="conductor2_nacimiento" v-model="seguro.conductor_2.nacimiento"  v-bind:class="{'error-border' : errors.includes('conductor_2_nacimiento')}" @change="addErrorsSecondPart()" >
                        </div>
                        <div class="col-12 col-sm-4 mt-2">
                          <label for="conductor2_carnet" class="form-label">Fecha 1º expedición carnet conducir <font-awesome-icon icon="info-circle" class="color hover_icon" data-bs-toggle="button" v-b-modal.carnet/> <span class="color">*</span></label>
                          <input type="date" class="form-control" id="conductor2_carnet" v-model="seguro.conductor_2.carnet" :max="maxDate"  v-bind:class="{'error-border' : errors.includes('conductor_2_carnet')}" @change="addErrorsSecondPart()">
                        </div>
                        <div class="col-12 col-sm-6 mt-4">
                          <p class="mb-0">¿Quieres añadir otro conductor adicional? <span class="color">*</span></p>
                        </div>
                        <div class="col-12 col-sm-6 mt-4">
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="radio_conductor_adicional_3" id="radio_conductor1" value="1" v-model="radio_conductor_adicional_3" @change="nextMiniDiv('conductor_adicional_3')">
                            <label class="form-check-label" for="radio_conductor_adicional">Sí</label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="radio_conductor_adicional_3" id="radio_conductor2" value="0" v-model="radio_conductor_adicional_3" @change="nextMiniDiv('conductor_adicional_3')">
                            <label class="form-check-label" for="radio_conductor_adicional">No</label>
                          </div>
                        </div>
                      </div>

                      <!-- Datos Conductor_3 -->

                      <div class="row align-items-start justify-content-center mt-2 d-none" id="conductor_adicional_3">

                        <div class="col-12 mt-2">
                          <h6 class="color">Tercer conductor adicional</h6>
                        </div>
                        <div class="col-12 col-sm-4 mt-2">
                          <label for="conductor3_nombre" class="form-label">Nombre <span class="color">*</span></label>
                          <input type="text" class="form-control" id="conductor3_nombre" v-model="seguro.conductor_3.nombre" v-bind:class="{'error-border' : errors.includes('conductor_3_nombre')}" @change="addErrorsSecondPart()">
                        </div>
                        <div class="col-12 col-sm-4 mt-2">
                          <label for="conductor3_apellido_1" class="form-label">Primer apellido <span class="color">*</span></label>
                          <input type="text" class="form-control" id="conductor3_apellido_1" v-model="seguro.conductor_3.apellido_1"  v-bind:class="{'error-border' : errors.includes('conductor_3_apellido_1')}" @change="addErrorsSecondPart()">
                        </div>
                        <div class="col-12 col-sm-4 mt-2">
                          <label for="conductor3_apellido_2" class="form-label">Segundo apellido</label>
                          <input type="text" class="form-control" id="conductor3_apellido_2" v-model="seguro.conductor_3.apellido_2">
                        </div>
                        <div class="col-12 col-sm-4 mt-2">
                          <label for="conductor3_dni" class="form-label">Identificación <font-awesome-icon icon="info-circle" class="color hover_icon" data-bs-toggle="button" v-b-modal.DniNie/> <span class="color">*</span></label>
                          <div class="row align-items-center justify-content-center">
                            <div class="col-5">
                              <select class="form-select" aria-label="conductor3_dni" v-model="seguro.conductor_3.dni_tipo" v-bind:class="{'error-border' : errors.includes('conductor_3_dni_tipo')}" @change="addErrorsSecondPart()">
                                <option selected>Selecciona tipo de documento</option>
                                <option value="dni">DNI</option>
                                <option value="nie">NIE</option>
                              </select>
                            </div>
                            <div class="col-7">
                              <input type="text" class="form-control" id="conductor3_dni" v-model="seguro.conductor_3.dni" v-bind:class="{'error-border' : errors.includes('conductor_3_dni')}" @change="addErrorsSecondPart()">
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-4 mt-2">
                          <label for="conductor3_nacimiento" class="form-label">Fecha nacimiento <span class="color">*</span></label>
                          <input type="date" class="form-control" id="conductor3_nacimiento" v-model="seguro.conductor_3.nacimiento"  v-bind:class="{'error-border' : errors.includes('conductor_3_nacimiento')}" @change="addErrorsSecondPart()">
                        </div>
                        <div class="col-12 col-sm-4 mt-2">
                          <label for="conductor3_carnet" class="form-label">Fecha 1º expedición carnet conducir <font-awesome-icon icon="info-circle" class="color hover_icon" data-bs-toggle="button" v-b-modal.carnet/> <span class="color">*</span></label>
                          <input type="date" class="form-control" id="conductor3_carnet" v-model="seguro.conductor_3.carnet" :max="maxDate"  v-bind:class="{'error-border' : errors.includes('conductor_3_carnet')}" @change="addErrorsSecondPart()" >
                        </div>
                        <div class="col-12 col-sm-6 mt-4">
                          <p class="mb-0">¿Quieres añadir otro conductor adicional? <span class="color">*</span></p>
                        </div>
                        <div class="col-12 col-sm-6 mt-4">
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="radio_conductor_adicional_4" id="radio_conductor1" value="1" v-model="radio_conductor_adicional_4" @change="nextMiniDiv('conductor_adicional_4')">
                            <label class="form-check-label" for="radio_conductor_adicional">Sí</label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="radio_conductor_adicional_4" id="radio_conductor2" value="0" v-model="radio_conductor_adicional_4" @change="nextMiniDiv('conductor_adicional_4')">
                            <label class="form-check-label" for="radio_conductor_adicional">No</label>
                          </div>
                        </div>
                      </div>

                      <!-- Datos Conductor_4 -->

                      <div class="row align-items-start justify-content-center mt-2 d-none" id="conductor_adicional_4">

                        <div class="col-12 mt-2">
                          <h6 class="color">Cuarto conductor adicional</h6>
                        </div>
                        <div class="col-12 col-sm-4 mt-2">
                          <label for="conductor4_nombre" class="form-label">Nombre <span class="color">*</span></label>
                          <input type="text" class="form-control" id="conductor4_nombre" v-model="seguro.conductor_4.nombre"  v-bind:class="{'error-border' : errors.includes('conductor_4_nombre')}" @change="addErrorsSecondPart()">
                        </div>
                        <div class="col-12 col-sm-4 mt-2">
                          <label for="conductor4_apellido_1" class="form-label">Primer apellido <span class="color">*</span></label>
                          <input type="text" class="form-control" id="conductor4_apellido_1" v-model="seguro.conductor_4.apellido_1" v-bind:class="{'error-border' : errors.includes('conductor_4_apellido_1')}" @change="addErrorsSecondPart()">
                        </div>
                        <div class="col-12 col-sm-4 mt-2">
                          <label for="conductor4_apellido_2" class="form-label">Segundo apellido</label>
                          <input type="text" class="form-control" id="conductor4_apellido_2" v-model="seguro.conductor_4.apellido_2" >
                        </div>
                        <div class="col-12 col-sm-4 mt-2">
                          <label for="conductor4_dni" class="form-label">Identificación <font-awesome-icon icon="info-circle" class="color hover_icon" data-bs-toggle="button" v-b-modal.DniNie/><span class="color">*</span></label>
                          <div class="row align-items-center justify-content-center">
                            <div class="col-5">
                              <select class="form-select" aria-label="conductor4_dni" v-model="seguro.conductor_4.dni_tipo" v-bind:class="{'error-border' : errors.includes('conductor_4_dni_tipo')}" @change="addErrorsSecondPart()">
                                <option selected>Selecciona tipo de documento</option>
                                <option value="dni">DNI</option>
                                <option value="nie">NIE</option>
                              </select>
                            </div>
                            <div class="col-7">
                              <input type="text" class="form-control" id="conductor4_dni" v-model="seguro.conductor_4.dni" v-bind:class="{'error-border' : errors.includes('conductor_4_dni')}" @change="addErrorsSecondPart()">
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-4 mt-2">
                          <label for="conductor4_nacimiento" class="form-label">Fecha nacimiento <span class="color">*</span></label>
                          <input type="date" class="form-control" id="conductor4_nacimiento" v-model="seguro.conductor_4.nacimiento"  v-bind:class="{'error-border' : errors.includes('conductor_4_nacimiento')}" @change="addErrorsSecondPart()">
                        </div>
                        <div class="col-12 col-sm-4 mt-2">
                          <label for="conductor4_carnet" class="form-label">Fecha 1º expedición carnet conducir <font-awesome-icon icon="info-circle" class="color hover_icon" data-bs-toggle="button" v-b-modal.carnet/> <span class="color">*</span></label>
                          <input type="date" class="form-control" id="conductor4_carnet" v-model="seguro.conductor_4.carnet" :max="maxDate" v-bind:class="{'error-border' : errors.includes('conductor_4_carnet')}" @change="addErrorsSecondPart()">
                        </div>
                        <div class="col-12 col-sm-6 mt-4">
                          <p class="mb-0">¿Quieres añadir otro conductor adicional? <span class="color">*</span></p>
                        </div>
                        <div class="col-12 col-sm-6 mt-4">
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="radio_conductor_adicional_5" id="radio_conductor1" value="1" v-model="radio_conductor_adicional_5" @change="nextMiniDiv('conductor_adicional_5')">
                            <label class="form-check-label" for="radio_conductor_adicional">Sí</label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="radio_conductor_adicional_5" id="radio_conductor2" value="0" v-model="radio_conductor_adicional_5" @change="nextMiniDiv('conductor_adicional_5')">
                            <label class="form-check-label" for="radio_conductor_adicional">No</label>
                          </div>
                        </div>
                      </div>

                      <!-- Datos Conductor_5 -->

                      <div class="row align-items-start justify-content-center mt-2 d-none" id="conductor_adicional_5">

                        <div class="col-12 mt-2">
                          <h6 class="color">Quinto conductor adicional</h6>
                        </div>
                        <div class="col-12 col-sm-4 mt-2">
                          <label for="conductor5_nombre" class="form-label">Nombre <span class="color">*</span></label>
                          <input type="text" class="form-control" id="conductor5_nombre" v-model="seguro.conductor_5.nombre" v-bind:class="{'error-border' : errors.includes('conductor_5_nombre')}" @change="addErrorsSecondPart()" >
                        </div>
                        <div class="col-12 col-sm-4 mt-2">
                          <label for="conductor5_apellido_1" class="form-label">Primer apellido <span class="color">*</span></label>
                          <input type="text" class="form-control" id="conductor5_apellido_1" v-model="seguro.conductor_5.apellido_1"  v-bind:class="{'error-border' : errors.includes('conductor_5_apellido_1')}" @change="addErrorsSecondPart()" >
                        </div>
                        <div class="col-12 col-sm-4 mt-2">
                          <label for="conductor5_apellido_2" class="form-label">Segundo apellido</label>
                          <input type="text" class="form-control" id="conductor5_apellido_2" v-model="seguro.conductor_5.apellido_2" >
                        </div>
                        <div class="col-12 col-sm-4 mt-2">
                          <label for="conductor5_dni" class="form-label">Identificación <font-awesome-icon icon="info-circle" class="color hover_icon" data-bs-toggle="button" v-b-modal.DniNie/><span class="color">*</span></label>
                          <div class="row align-items-center justify-content-center">
                            <div class="col-5">
                              <select class="form-select" aria-label="conductor5_dni" v-model="seguro.conductor_5.dni_tipo" v-bind:class="{'error-border' : errors.includes('conductor_5_dni_tipo')}" @change="addErrorsSecondPart()">
                                <option selected>Selecciona tipo de documento</option>
                                <option value="dni">DNI</option>
                                <option value="nie">NIE</option>
                              </select>
                            </div>
                            <div class="col-7">
                              <input type="text" class="form-control" id="conductor5_dni" v-model="seguro.conductor_5.dni"  v-bind:class="{'error-border' : errors.includes('conductor_5_dni')}" @change="addErrorsSecondPart()" >
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-4 mt-2">
                          <label for="conductor5_nacimiento" class="form-label">Fecha nacimiento <span class="color">*</span></label>
                          <input type="date" class="form-control" id="conductor5_nacimiento" v-model="seguro.conductor_5.nacimiento"  v-bind:class="{'error-border' : errors.includes('conductor_5_nacimiento')}" @change="addErrorsSecondPart()" > 
                        </div>
                        <div class="col-12 col-sm-4 mt-2">
                          <label for="conductor5_carnet" class="form-label">Fecha 1º expedición carnet conducir <font-awesome-icon icon="info-circle" class="color hover_icon" data-bs-toggle="button" v-b-modal.carnet/> <span class="color">*</span></label>
                          <input type="date" class="form-control" id="conductor5_carnet" v-model="seguro.conductor_5.carnet" :max="maxDate"  v-bind:class="{'error-border' : errors.includes('conductor_5_carnet')}" @change="addErrorsSecondPart()" >
                        </div>
                      </div>
                    </div>

                    <div class="col-12 mt-4 mb-4 text-center">
                      <button v-if="this.advice_25 == true" type="button" class="btn btn-primary" v-b-modal.alert_25 >Paso Siguiente</button>
                      <button v-else-if="this.advice_25 == false" type="button" class="btn btn-primary" @click="formParttwo()">Siguiente paso</button>
                    </div>

                    <div class="col-12 text-center" v-if="radio_empresa == 0 && this.$v.seguro.tomador.$invalid">
                      <div class="error" v-if="submitted2 && !$v.seguro.tomador.required">Faltan campos por completar en el tomador</div>
                      <div class="error" v-if="submitted2 && !$v.seguro.tomador.dni.dni">El número de identificación no es válido</div>
                    </div>
                    <div class="col-12 text-center" v-if="radio_empresa == 1 && this.$v.seguro.empresa.$invalid">
                      <div class="error" v-if="submitted2 && !$v.seguro.empresa.required">Faltan campos por completar en el tomador</div>
                      <div class="error" v-if="submitted2 && !$v.seguro.empresa.tomador_empresa.cif">El número de CIF no es válido</div>
                    </div>
                    <div class="col-12 text-center" v-if="submitted2 && !radio_propietario">
                      <div class="error">Faltan campos por completar en el apartado propietario</div>
                    </div>
                    <div class="col-12 text-center" v-if="submitted2 && !radio_antecedentes">
                      <div class="error">Faltan campos por completar en el apartado bonificación</div>
                    </div>
                    <div class="col-12 text-center" v-if="radio_propietario == 1 && this.$v.seguro.propietario.$invalid">
                      <div class="error" v-if="submitted2 && !$v.seguro.propietario.required">Faltan campos por completar en el apartado propietario</div>
                      <div class="error" v-if="submitted2 && !$v.seguro.propietario.dni.dni">El número de identificación no es válido</div>
                    </div>
                    <div class="col-12 text-center" v-if="radio_antecedentes == 1 && this.$v.seguro.antecedentes.$invalid">
                      <div class="error" v-if="submitted2 && !$v.seguro.antecedentes.required">Faltan campos por completar en el apartado bonificación bis</div>
                    </div>

                  </div>

                  <!-- Resumen -->
                  <div class="row align-items-start justify-content-center alldivs d-none" id="resumen">
                    
                    <div class="col-sm-12 mt-3 text-center">
                      <h4 class="color"><img src="../assets/img/form_1_3.png" class="img-fluid"></h4>
                    </div>
                    
                    <div class="col-12 text-end">
                      <a type="button" class="color ps-3" @click="rear('resumen','tomador')">&lt;&lt; Atrás</a>
                    </div>
                    <div class="col-12">
                      <h3 class="color">Resumen de tu datos</h3>
                    </div>

                    <div class="col-12 col-sm-6 mt-1 p-4 pt-1">
                      <div class="row align-items-start justify-content-center">
                        <div class="col-12 bg-white rounded p-3">
                          <h4 class="color">Datos del vehículo</h4>
                          <p>Tipo de auto: {{ this.tipo_auto_bonito }}<br>
                          Potencia: {{ this.tipo_potencia_bonito }}<br>
                          Marca: {{ this.seguro.vehiculo.marca }}<br>
                          Modelo: {{ this.seguro.vehiculo.modelo }}<br>
                          Matrícula: {{ this.seguro.vehiculo.matricula }}<br>
                          Año de matriculación: {{ this.seguro.vehiculo.fecha_matriculacion }}<br>
                          Valor vehículo: {{ this.seguro.vehiculo.valor_autocaravana }}<br>
                          Valor accesorios: {{ this.seguro.vehiculo.valor_accesorios }}<br>
                          Valor total: {{ Math.abs(Number(seguro.vehiculo.valor_autocaravana) + Number(seguro.vehiculo.valor_accesorios)) }}</p>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-sm-6 mt-1 p-4 pt-1" v-if="radio_empresa == 0">
                      <div class="row align-items-start justify-content-center">
                        <div class="col-12 bg-white rounded p-3">
                          <h4 class="color">Datos del tomador</h4>
                          <p>Nombre: {{ this.seguro.tomador.nombre }} {{ this.seguro.tomador.apellido_1 }} {{ this.seguro.tomador.apellido_2 }}<br>
                          Identificación: {{ this.seguro.tomador.dni_tipo }} {{ this.seguro.tomador.dni }}<br>
                          Fecha de nacimiento: {{ this.seguro.tomador.nacimiento }}<br>
                          Fecha 1º expedición carnet conducir: {{ this.seguro.tomador.carnet }}<br>
                          Dirección: {{ this.seguro.tomador.direccion_texto }} {{ this.seguro.tomador.direccion_numero }} {{ this.seguro.tomador.direccion_piso }} {{ this.seguro.tomador.direccion_puerta }}<br>
                          Código postal: {{ this.seguro.tomador.postal }}<br>
                          Localidad: {{ this.seguro.tomador.localidad }}<br>
                          Teléfono: {{ this.seguro.tomador.telefono }}<br>
                          Email: {{ this.seguro.tomador.email }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 mt-1 p-4 pt-1" v-else-if="radio_empresa == 1">
                      <div class="row align-items-start justify-content-center">
                        <div class="col-12 bg-white rounded p-2">
                          <h4 class="color">Datos del tomador empresa</h4>
                          <p>Nombre empresa: {{ this.seguro.empresa.tomador_empresa.nombre }}<br>
                          Identificación: CIF {{ this.seguro.empresa.tomador_empresa.cif }}<br>
                          Dirección: {{ this.seguro.empresa.tomador_empresa.direccion_texto }} {{ this.seguro.empresa.tomador_empresa.direccion_numero }} {{ this.seguro.empresa.direccion_piso }} {{ this.seguro.empresa.direccion_puerta }}<br>
                          Código postal: {{ this.seguro.empresa.tomador_empresa.postal }}<br>
                          Localidad: {{ this.seguro.empresa.tomador_empresa.localidad }}<br>
                          Teléfono: {{ this.seguro.empresa.tomador_empresa.telefono }}<br>
                          Email: {{ this.seguro.empresa.tomador_empresa.email }}<br>
                          <h4 class="color">Datos Conductor principal</h4>
                          <p>Nombre: {{ this.seguro.empresa.conductor_pral.nombre }} {{ this.seguro.empresa.conductor_pral.apellido_1 }} {{ this.seguro.empresa.conductor_pral.apellido_2 }}<br>
                          Identificación: {{ this.seguro.empresa.conductor_pral.dni_tipo }} {{ this.seguro.empresa.conductor_pral.dni }}<br>
                          Fecha de nacimiento: {{ this.seguro.empresa.conductor_pral.nacimiento }}<br>
                          Fecha 1º expedición carnet conducir: {{ this.seguro.empresa.conductor_pral.carnet }}</p>
                        </div>
                      </div>
                      
                    </div>

                    <div class="col-12 col-sm-6 mt-1 p-4 pt-1" v-if="this.radio_antecedentes == 1">
                      <div class="row align-items-start justify-content-center">
                        <div class="col-12 bg-white rounded p-3">
                          <h4 class="color">Antecedentes</h4>
                          <p>Compañía actual: {{ this.seguro.antecedentes.compania }}<br>
                          Póliza: {{ this.seguro.antecedentes.poliza }}<br>
                          Matrícula bonificación: {{ this.seguro.antecedentes.matricula_bonificacion }}</p>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-sm-6 mt-1 p-4 pt-1" v-if="this.radio_propietario == 1">
                      <div class="row align-items-start justify-content-center">
                        <div class="col-12 bg-white rounded p-3">
                          <h4 class="color">Datos del propietario</h4>
                          <p>Nombre: {{ this.seguro.propietario.nombre }} {{ this.seguro.propietario.apellido_1 }} {{ this.seguro.propietario.apellido_2 }}<br>
                          Identificación: {{ this.seguro.propietario.dni_tipo }} {{ this.seguro.propietario.dni }}<br>
                          Fecha de nacimiento: {{ this.seguro.propietario.nacimiento }}<br>
                          Fecha 1º expedición carnet conducir: {{ this.seguro.propietario.carnet }}<br>
                          Dirección: {{ this.seguro.propietario.direccion_texto }} {{ this.seguro.propietario.direccion_numero }} {{ this.seguro.propietario.direccion_piso }} {{ this.seguro.propietario.direccion_puerta }}<br>
                          Código postal: {{ this.seguro.propietario.postal }}<br>
                          Localidad: {{ this.seguro.propietario.localidad }}<br>
                          Teléfono: {{ this.seguro.propietario.telefono }}<br>
                          Email: {{ this.seguro.propietario.email }}</p>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-sm-6 mt-1 p-4 pt-1" v-if="this.radio_conductor_adicional_1 == 1">
                      <div class="row align-items-start justify-content-center">
                        <div class="col-12 bg-white rounded p-3">
                          <h4 class="color">Datos del conductor adicional 1</h4>
                          <p>Nombre: {{ this.seguro.conductor_1.nombre }} {{ this.seguro.conductor_1.apellido_1 }} {{ this.seguro.conductor_1.apellido_2 }}<br>
                          Identificación: {{ this.seguro.conductor_1.dni_tipo }} {{ this.seguro.conductor_1.dni }}<br>
                          Fecha de nacimiento: {{ this.seguro.conductor_1.nacimiento }}<br>
                          Fecha 1º expedición carnet conducir: {{ this.seguro.conductor_1.carnet }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 mt-1 p-4 pt-1" v-if="this.radio_conductor_adicional_2 == 1">
                      <div class="row align-items-start justify-content-center">
                        <div class="col-12 bg-white rounded p-3">
                          <h4 class="color">Datos del conductor adicional 2</h4>
                          <p>Nombre: {{ this.seguro.conductor_2.nombre }} {{ this.seguro.conductor_2.apellido_1 }} {{ this.seguro.conductor_2.apellido_2 }}<br>
                          Identificación: {{ this.seguro.conductor_2.dni_tipo }} {{ this.seguro.conductor_2.dni }}<br>
                          Fecha de nacimiento: {{ this.seguro.conductor_2.nacimiento }}<br>
                          Fecha 1º expedición carnet conducir: {{ this.seguro.conductor_2.carnet }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 mt-1 p-4 pt-1" v-if="this.radio_conductor_adicional_3 == 1">
                      <div class="row align-items-start justify-content-center">
                        <div class="col-12 bg-white rounded p-3">
                          <h4 class="color">Datos del conductor adicional 3</h4>
                          <p>Nombre: {{ this.seguro.conductor_3.nombre }} {{ this.seguro.conductor_3.apellido_1 }} {{ this.seguro.conductor_3.apellido_2 }}<br>
                          Identificación: {{ this.seguro.conductor_3.dni_tipo }} {{ this.seguro.conductor_3.dni }}<br>
                          Fecha de nacimiento: {{ this.seguro.conductor_3.nacimiento }}<br>
                          Fecha 1º expedición carnet conducir: {{ this.seguro.conductor_3.carnet }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 mt-1 p-4 pt-1" v-if="this.radio_conductor_adicional_4 == 1">
                      <div class="row align-items-start justify-content-center">
                        <div class="col-12 bg-white rounded p-3">
                          <h4 class="color">Datos del conductor adicional 4</h4>
                          <p>Nombre: {{ this.seguro.conductor_4.nombre }} {{ this.seguro.conductor_4.apellido_1 }} {{ this.seguro.conductor_4.apellido_2 }}<br>
                          Identificación: {{ this.seguro.conductor_4.dni_tipo }} {{ this.seguro.conductor_4.dni }}<br>
                          Fecha de nacimiento: {{ this.seguro.conductor_4.nacimiento }}<br>
                          Fecha 1º expedición carnet conducir: {{ this.seguro.conductor_4.carnet }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 mt-1 p-4 pt-1" v-if="this.radio_conductor_adicional_5 == 1">
                      <div class="row align-items-start justify-content-center">
                        <div class="col-12 bg-white rounded p-3">
                          <h4 class="color">Datos del conductor adicional 5</h4>
                          <p>Nombre: {{ this.seguro.conductor_5.nombre }} {{ this.seguro.conductor_5.apellido_1 }} {{ this.seguro.conductor_5.apellido_2 }}<br>
                          Identificación: {{ this.seguro.conductor_5.dni_tipo }} {{ this.seguro.conductor_5.dni }}<br>
                          Fecha de nacimiento: {{ this.seguro.conductor_5.nacimiento }}<br>
                          Fecha 1º expedición carnet conducir: {{ this.seguro.conductor_5.carnet }}</p>
                        </div>
                      </div>
                    </div>

                    <!-- Validate Resumen -->
                    <div class="col-12 mt-3">
                      <input type="checkbox" class="form-check-input" @change="samplefunction(1,$event)" id="input_privacitat" v-model="privacitat_acceptada">
                      <label class="form-check-label" for="exampleCheck1">He leído y acepto la <a href="/privacidad" target="_blank"> Política de privacidad</a> </label>
                    </div>

                    <div class="col-12 mt-3 text-center" v-if="(!mostrarInputSend)">
                      <button type="button" class="btn btn-primary" disabled style="border: 1px solid #8c173847; background: #8c1738d9 !important">Confirmar y enviar datos</button>
                    </div>
                    <div class="col-12 mt-3 text-center" v-if="(mostrarInputSend)">
                      <button type="button" class="btn btn-primary" @click="formPartthree()">Confirmar y enviar datos</button>
                    </div>


                    <div class="col-12 mt-3 text-center">
                      <router-link to="/" class="color" >Cancelar</router-link>
                    </div>

                  </div>

                  <!-- END Resumen -->
                  <!-- Cierre -->

                  <div class="row align-items-start justify-content-center alldivs d-none" id="cierre">
                    <div class="col-12 text-center">
                      <h3 class="color mb-3">¡Gracias por confiar en Conficaravan!</h3>
                      <p>Tu solicitud se ha enviado correctamente.</p>
                      <p>Nuestro equipo revisará todos tus datos y te enviará tu presupuesto por correo electrónico.</p>
                      <p>Saludos.</p>
                    </div>

                    <div class="mt-3 text-center">
                      <router-link to="/" class="color btn btn-primary" >Volver al inicio</router-link>
                    </div>
                  </div>

                </form>

                <div class="col-12 text-start" id="aviso_campos">
                  <div class="row align-items-start justify-content-center">
                    <div class="col-12 text-start ps-4">
                      <p class="small color">* Los campos marcados con asterísco son obligatorios</p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import { VueDatePicker } from '@mathieustan/vue-datepicker';
//import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';
import axios from 'axios';

import { helpers, required, minLength, maxLength, email, between } from "vuelidate/lib/validators";
const matricula = helpers.regex('matricula', /^([A-Za-z]{1,2})(\d{4})([A-Za-z]{0,2})|([0-9]{4}([A-Za-z]){3})$/);
const year = helpers.regex('year', /^[0-9]{4}/);
const dni = helpers.regex('dni', /^(([XYZ]|[xyz])\d{7,8}[A-Za-z])|(\d{8})([A-Za-z])|([ABCDEFGHJKLMNPQRSUVW]|[abcdefghjklmnpqrsuvw])(\d{7})([0-9A-J])$/);
const cif = helpers.regex('cif', /([a-z]|[A-Z]|[0-9])[0-9]{7}([a-z]|[A-Z]|[0-9])/);
const postal = helpers.regex('postal', /^[0-9]{5}$/);

export default {
  name: 'Confirmar',
  components : {
  },
  validations: {
    seguro: {
      vehiculo: {
        tipo_auto: {
          required
        },
        potencia: {
          required,
        },
        marca: {
          required,
          minLength: minLength(1),
        },
        modelo: {
          required,
          minLength: minLength(1),
        },
        matricula: {
          matricula,
        },
        fecha_matriculacion: {
          year,
          required,
          between: between(1900, 2100),
          maxLength: maxLength(4)
        },
        valor_autocaravana: {
          required,
        },
        valor_accesorios: {
          required,
        },
      },
      empresa: {
        tomador_empresa: {
          nombre: {
            required,
            minLength: minLength(2),
          },
          cif: {
            required,
            cif
          },
          direccion_texto: {
            required
          },
          tipo_de_via: {
            required
          },
          postal: {
            required,
            postal
          },
          localidad: {
            required
          },
          telefono: {
            required
          },
          email: {
            required,
            email
          },
        },
        conductor_pral: {
          nombre: {
            required,
            minLength: minLength(2),
          },
          apellido_1: {
            required,
            minLength: minLength(2),
          },
          dni_tipo: {
            required
          },
          dni: {
            required,
            dni
          },
          nacimiento: {
            required
          },
          carnet: {
            required
          },
        },
      },
      tomador: {
        nombre: {
          required,
          minLength: minLength(2),
        },
        apellido_1: {
          required,
          minLength: minLength(2),
        },
        dni_tipo: {
          required
        },
        dni: {
          required,
          dni
        },
        nacimiento: {
          required
        },
        carnet: {
          required
        },
        direccion_texto: {
          required
        },
        tipo_de_via: {
          required
        },
        postal: {
          required,
          postal
        },
        localidad: {
          required
        },
        telefono: {
          required
        },
        email: {
          required,
          email
        }
      },
      antecedentes: {
        compania: {
          required,
          minLength: minLength(2)
        },
        poliza: {
          required,
          minLength: minLength(2)
        },
        matricula_bonificacion: {
          matricula,
          required,
          minLength: minLength(2)
        }
      },
      propietario: {
        nombre: {
          required,
          minLength: minLength(2),
        },
        apellido_1: {
          required,
          minLength: minLength(2),
        },
        dni_tipo: {
          required
        },
        dni: {
          required,
          dni
        },
        nacimiento: {
          required
        },
        carnet: {
          required
        },
        direccion_texto: {
          required
        },
        postal: {
          required,
          postal
        },
        localidad: {
          required
        },
        telefono: {
          required
        },
        email: {
          required,
          email
        }
      },
      conductor_1: {
        nombre: {
          required,
          minLength: minLength(2),
        },
        apellido_1: {
          required,
          minLength: minLength(2),
        },
        dni_tipo: {
          required
        },
        dni: {
          required,
          dni
        },
        nacimiento: {
          required
        },
        carnet: {
          required
        }
      },
      conductor_2: {
        nombre: {
          required,
          minLength: minLength(2),
        },
        apellido_1: {
          required,
          minLength: minLength(2),
        },
        dni_tipo: {
          required
        },
        dni: {
          required,
          dni
        },
        nacimiento: {
          required
        },
        carnet: {
          required
        }
      },
      conductor_3: {
        nombre: {
          required,
          minLength: minLength(2),
        },
        apellido_1: {
          required,
          minLength: minLength(2),
        },
        dni_tipo: {
          required
        },
        dni: {
          required,
          dni
        },
        nacimiento: {
          required
        },
        carnet: {
          required
        }
      },
      conductor_4: {
        nombre: {
          required,
          minLength: minLength(2),
        },
        apellido_1: {
          required,
          minLength: minLength(2),
        },
        dni_tipo: {
          required
        },
        dni: {
          required,
          dni
        },
        nacimiento: {
          required
        },
        carnet: {
          required
        }
      },
      conductor_5: {
        nombre: {
          required,
          minLength: minLength(2),
        },
        apellido_1: {
          required,
          minLength: minLength(2),
        },
        dni_tipo: {
          required
        },
        dni: {
          required,
          dni
        },
        nacimiento: {
          required
        },
        carnet: {
          required
        }
      },
    }
  },
  data() {
    return {
      Comunicacion : false,
      EnvInfCom : false,
      EnvInfGral : false,
      EnviarSms : false,
      ComCarta : false,
      privacitat_acceptada: false,
      amagarInputOtros: true,
      amagarInputCodigo : true,
      amagarInputEnviar : true,
      mostrarInputSend: false,
      tipo_auto_bonito: '',
      tipo_potencia_bonito: '',
      submitted: false,
      submitted2: false,
      submitted3: false,
      radio_propietario: '',
      radio_antecedentes: '',
      radio_empresa: '0',
      radio_conductor_adicional_1: '',
      radio_conductor_adicional_2: '0',
      radio_conductor_adicional_3: '0',
      radio_conductor_adicional_4: '0',
      radio_conductor_adicional_5: '0',
      advice_25: false,
      errors: [],
      seguro: {
        vehiculo: {
          marca: '',
          modelo: '',
          potencia: '',
          matricula: '',
          fecha_matriculacion: '',
          tipo_auto: '',
          valor_autocaravana: '',
          valor_accesorios: '',
          valor_total: ''
        },
        antecedentes: {
          compania: '',
          poliza: '',
          matricula_bonificacion: ''
        },
        empresa: {
          tomador_empresa: {
            nombre: '',
            cif: '',
            direccion_texto: '',
            direccion_numero: '',
            direccion_piso: '',
            direccion_puerta: '',
            postal: '',
            localidad: '',
            telefono: '',
            email: ''
          },
          conductor_pral: {
            nombre: '',
            apellido_1: '',
            apellido_2: '',
            dni_tipo: '',
            dni: '',
            nacimiento: '',
            carnet: '',
          },
        },
        tomador: {
          nombre: '',
          apellido_1: '',
          apellido_2: '',
          dni_tipo: '',
          dni: '',
          nacimiento: '',
          carnet: '',
          direccion_texto: '',
          direccion_numero: '',
          direccion_piso: '',
          direccion_puerta: '',
          postal: '',
          localidad: '',
          telefono: '',
          email: ''
        },
        propietario: {
          nombre: '',
          apellido_1: '',
          apellido_2: '',
          dni: '',
          nacimiento: '',
          carnet: '',
          direccion: '',
          postal: '',
          telefono: '',
          email: ''
        },
        conductor_1: {
          nombre: '',
          apellido_1: '',
          apellido_2: '',
          dni: '',
          nacimiento: '',
          carnet: ''
        },
        conductor_2: {
          nombre: '',
          apellido_1: '',
          apellido_2: '',
          dni: '',
          nacimiento: '',
          carnet: ''
        },
        conductor_3: {
          nombre: '',
          apellido_1: '',
          apellido_2: '',
          dni: '',
          nacimiento: '',
          carnet: ''
        },
        conductor_4: {
          nombre: '',
          apellido_1: '',
          apellido_2: '',
          dni: '',
          nacimiento: '',
          carnet: ''
        },
        conductor_5: {
          nombre: '',
          apellido_1: '',
          apellido_2: '',
          dni: '',
          nacimiento: '',
          carnet: ''
        }
        
      },
      maxDate: new Date().toISOString().split('T')[0], // Obtiene la fecha actual en el formato yyyy-mm-dd
    } 
  },
  mounted: function() {
    if(this.$route.query.t && this.$route.query.p ){
      this.seguro.vehiculo.tipo_auto = this.$route.query.t;
      this.seguro.vehiculo.potencia = this.$route.query.p;

      console.log(this.seguro.vehiculo.tipo_auto, this.seguro.vehiculo.potencia);
    }else{
      console.log(this.seguro.vehiculo.tipo_auto, this.seguro.vehiculo.potencia);
    }

    window.scrollTo( 0, 0 );
    
  },
  methods: {
    comprovarValueConocido(){
        var id_conocido = this.seguro.como_conocido
        if(id_conocido == 4){
            this.amagarInputOtros = false;
        }else{
        this.amagarInputOtros = true;
        }
    },
    samplefunction : function(value,event) {
      if (event.target.checked) {
        this.mostrarInputSend = true;
        console.log('selected');
      }else{
        this.mostrarInputSend = false;
      }
    },
    comprovarCodigoPromocional(){
        var tiene_codigo_promocional = this.seguro.tiene_codigo_promocional
        if(tiene_codigo_promocional == 1){
            this.amagarInputCodigo = false;
        }else{
          this.amagarInputCodigo = true;
        }
    },
    comprovarPrivacitat(){
      console.log("comprovarPrivacitat")
      var privacitat_acceptada = this.privacitat_acceptada;
      console.log(privacitat_acceptada);
      if(this.privacitat_acceptada != "") {
        console.log('isSelected');
      }

    },
    rear(a,b){
      document.getElementById(a).classList.add('d-none');
      document.getElementById(b).classList.remove('d-none');
    },
    formPartOne(){
      this.submitted = true;
      this.errors = [];
      this.$v.seguro.vehiculo.$touch();

      if(this.$v.seguro.vehiculo.$invalid){
        this.addErrorsVehiculo(true)
        return false;
      }else{
        if(this.seguro.vehiculo.tipo_auto == 1){
          this.tipo_auto_bonito = 'Camper';
        }else{
          this.tipo_auto_bonito = 'Autocaravana';
        }

        if(this.seguro.vehiculo.potencia == 1){
          this.tipo_potencia_bonito = 'Hasta 120 CV';
        }else if(this.seguro.vehiculo.potencia == 2){
          this.tipo_potencia_bonito = 'Hasta 140 CV';
        } else if(this.seguro.vehiculo.potencia == 3){
          this.tipo_potencia_bonito = 'Hasta 160 CV';
        } else if(this.seguro.vehiculo.potencia == 4){
          this.tipo_potencia_bonito = 'Más de 160 CV';
        }

        this.$bvModal.show('alert_menores');
        document.getElementById('vehiculo').classList.add('d-none');
        document.getElementById('tomador').classList.remove('d-none');
      }

      // console.log('pas 1 confirmar');
      this.$gtag.event('confirmar_siguiente', {
        'event_name': 'confirmar_siguiente'
      })
    },
    addErrorsVehiculo(addNewErrors = false){
      this.addErrors(this.$v.seguro.vehiculo,'vehiculo',true,addNewErrors)
    },

    addErrorsSecondPart(addNewErrors = false){
      const oldErrors = [...this.errors]
      this.errors = [];

      if(this.radio_empresa == 1){ //afegir empresa
        this.addErrors(this.$v.seguro.empresa, 'empresa', false, addNewErrors,oldErrors);
      }else{
        this.addErrors(this.$v.seguro.tomador, 'tomador', false, addNewErrors,oldErrors);
      }

      if(this.radio_antecedentes == 1){
        this.addErrors(this.$v.seguro.antecedentes, 'antecedentes', false, addNewErrors,oldErrors);
      }

      if(this.radio_propietario == 1){
        this.addErrors(this.$v.seguro.propietario, 'propietario', false, addNewErrors,oldErrors);
      }

      if(this.radio_conductor_adicional_1 == 1){
        this.addErrors(this.$v.seguro.conductor_1, 'conductor_1', false, addNewErrors,oldErrors);
      }

      if(this.radio_conductor_adicional_2 == 1){
        this.addErrors(this.$v.seguro.conductor_2, 'conductor_2', false, addNewErrors,oldErrors);
      }


      if(this.radio_conductor_adicional_3 == 1){
        this.addErrors(this.$v.seguro.conductor_3, 'conductor_3', false, addNewErrors,oldErrors);
      }


      if(this.radio_conductor_adicional_4 == 1){
        this.addErrors(this.$v.seguro.conductor_4, 'conductor_4', false, addNewErrors,oldErrors);
      }


      if(this.radio_conductor_adicional_5 == 1){
        this.addErrors(this.$v.seguro.conductor_5, 'conductor_5', false, addNewErrors,oldErrors);
      }

    },


    formParttwo(){
      this.submitted2 = true;
      this.errors = [];
      // console.log('pas 2 confirmar');

      this.$gtag.event('confirmar_siguiente_paso', {
        'event_name': 'confirmar_siguiente_paso'
      })
      
      this.addErrorsSecondPart(true)
      console.log(this.errors)
  

      const fieldsToValidate = {
        'tomador' : 'tomador',
        'radio_propietario' : 'propietario',
        'radio_antecedentes' : 'antecedentes',
        'radio_empresa' : 'empresa',
        'radio_conductor_adicional_1' : 'conductor_1',
        'radio_conductor_adicional_2' : 'conductor_2',
        'radio_conductor_adicional_3' : 'conductor_3',
        'radio_conductor_adicional_4' : 'conductor_4',
        'radio_conductor_adicional_5' : 'conductor_5',
      };
      for (const key in fieldsToValidate) {
        if (key != 'tomador' && this[key] !== true && this[key] == "0") {
          console.log("skipping " + key, this[key], this[key].length)
          continue;
        }else if(key == 'tomador' && this.radio_empresa == "1"){
          console.log("skipping " + key, this[key], this.empresa)
          continue;
        }
      
        const field = fieldsToValidate[key];

        this.$v.seguro[field].$touch();


        if(this.$v.seguro[field].$invalid) {
          Object.keys(this.$v.seguro).forEach(element => {
            if (element.includes('$')){
              return
            }
          });
          return false;
        }
      }




      document.getElementById('tomador').classList.add('d-none');
      document.getElementById('aviso_campos').classList.add('d-none');
      document.getElementById('resumen').classList.remove('d-none');
      return true;

    },
    addErrors(object = this.$v,parentString = null,firstTime = true,addNewErrors = true,oldErrors  = [...this.errors] ){
      if(firstTime){
         this.errors = [];
      }

      Object.keys(object).forEach((key) => {
        if(!key.includes('$')){
          let name = ""
          if(parentString){
            name += parentString+'_'
          }
          name += key
          if(addNewErrors || oldErrors.includes(name)){
            const invalid = object[key].$invalid
            if(invalid){
              this.errors.push(name)
              this.addErrors(object[key],name,false)
            }
          }          
        }
      })
    },
    formParttwoSpecial(){
      console.log('formParttwoSpecial');

      this.submitted2 = true;

      this.seguro.tomador.nombre = this.seguro.propietario.nombre;
      this.seguro.tomador.apellido_1 = this.seguro.propietario.apellido_1;
      this.seguro.tomador.apellido_2 = this.seguro.propietario.apellido_2;
      this.seguro.tomador.dni_tipo = this.seguro.propietario.dni_tipo;
      this.seguro.tomador.dni = this.seguro.propietario.dni;
      this.seguro.tomador.nacimiento = this.seguro.propietario.nacimiento;
      this.seguro.tomador.carnet = this.seguro.propietario.carnet;
      this.seguro.tomador.direccion_texto = this.seguro.propietario.direccion_texto;
      this.seguro.tomador.tipo_de_via = this.seguro.propietario.tipo_de_via;
      this.seguro.tomador.direccion_numero = this.seguro.propietario.direccion_numero;
      this.seguro.tomador.direccion_piso = this.seguro.propietario.direccion_piso;
      this.seguro.tomador.direccion_puerta = this.seguro.propietario.direccion_puerta;
      this.seguro.tomador.postal = this.seguro.propietario.postal;
      this.seguro.tomador.localidad = this.seguro.propietario.localidad;
      this.seguro.tomador.telefono = this.seguro.propietario.telefono;
      this.seguro.tomador.email = this.seguro.propietario.email;


      const fieldsToValidate = {
        'tomador' : 'tomador',
        'radio_propietario' : 'propietario',
        'radio_antecedentes' : 'antecedentes',
        'radio_empresa' : 'empresa',
        'radio_conductor_adicional_1' : 'conductor_1',
        'radio_conductor_adicional_2' : 'conductor_2',
        'radio_conductor_adicional_3' : 'conductor_3',
        'radio_conductor_adicional_4' : 'conductor_4',
        'radio_conductor_adicional_5' : 'conductor_5',
      };

      for (const key in fieldsToValidate) {
        if (key != 'tomador' && this[key] !== true &&
        this[key] == "0"
        ) {
          console.log("skipping " + key, this[key], this[key].length)
          continue;
        }else if(key == 'tomador' && this.radio_empresa == "1"){
          console.log("skipping " + key, this.antecedentes)
          continue;
        }

        const field = fieldsToValidate[key];
        this.$v.seguro[field].$touch();

        if(this.$v.seguro[field].$invalid) {
          console.log(field);
          Object.keys(this.$v.seguro).forEach(element => {
            
            if (element.includes('$')){
              return
            }
            console.log(element, this.$v.seguro[element]);
          });
          return false;
        }
      }

      //console.log('all ok');
      this.$bvModal.hide('alert_25');
      document.getElementById('tomador').classList.add('d-none');
      document.getElementById('aviso_campos').classList.add('d-none');
      document.getElementById('resumen').classList.remove('d-none');
      return true;

    },
    formPartthree(){
      
        this.submitted3 = true;

        var como_conocido = document.getElementById('como_conocido');
        var como_conocido_otros = document.getElementById('como_conocido_otros');
        var codigo_promocional = document.getElementById('codigo_promocional');


        this.seguro.como_conocido = como_conocido.value;
        if(como_conocido_otros){
            this.seguro.como_conocido_otros = como_conocido_otros.value;
        }else{
            this.seguro.como_conocido_otros = null;
        }


        if(this.seguro.tiene_codigo_promocional == true){
            this.seguro.tiene_codigo_promocional = 1;
        }else{
            this.seguro.tiene_codigo_promocional = 0;
        }

        if(codigo_promocional){
            this.seguro.codigo_promocional = codigo_promocional.value;
        }else{
            this.seguro.codigo_promocional = null;
        }

      axios.post('https://conficaravan.com/code/mail_big.php', {

        Comunicacion : this.Comunicacion,
        EnvInfCom : this.EnvInfCom,
        EnvInfGral : this.EnvInfGral,
        EnviarSms : this.EnviarSms,
        ComCarta : this.ComCarta,

        radio_propietario: this.radio_propietario,
        radio_antecedentes: this.radio_antecedentes,
        radio_empresa: this.radio_empresa,
        radio_conductor_adicional_1: this.radio_conductor_adicional_1,
        radio_conductor_adicional_2: this.radio_conductor_adicional_2,
        radio_conductor_adicional_3: this.radio_conductor_adicional_3,
        radio_conductor_adicional_4: this.radio_conductor_adicional_4,
        radio_conductor_adicional_5: this.radio_conductor_adicional_5,

        vehiculo_marca: this.seguro.vehiculo.marca,
        vehiculo_modelo: this.seguro.vehiculo.modelo,
        vehiculo_potencia: this.seguro.vehiculo.potencia,
        vehiculo_matricula: this.seguro.vehiculo.matricula,
        vehiculo_fecha_matriculacion: this.seguro.vehiculo.fecha_matriculacion,
        vehiculo_tipo_auto: this.seguro.vehiculo.tipo_auto,
        vehiculo_valor_autocaravana: this.seguro.vehiculo.valor_autocaravana,
        vehiculo_valor_accesorios: this.seguro.vehiculo.valor_accesorios,
        vehiculo_valor_total: this.seguro.vehiculo.valor_total,

        antecedentes_compania: this.seguro.antecedentes.compania,
        antecedentes_poliza: this.seguro.antecedentes.poliza,
        antecedentes_matricula_bonificacion: this.seguro.antecedentes.matricula_bonificacion,

        empresa_nombre: this.seguro.empresa.tomador_empresa.nombre,
        empresa_cif: this.seguro.empresa.tomador_empresa.cif, 
        empresa_direccion_texto: this.seguro.empresa.tomador_empresa.direccion_texto,
        empresa_tipo_de_via: this.seguro.empresa.tomador_empresa.tipo_de_via,
        empresa_direccion_numero: this.seguro.empresa.tomador_empresa.direccion_numero,
        empresa_direccion_piso: this.seguro.empresa.tomador_empresa.direccion_piso,
        empresa_direccion_puerta: this.seguro.empresa.tomador_empresa.direccion_puerta,
        empresa_postal: this.seguro.empresa.tomador_empresa.postal,
        empresa_localidad: this.seguro.empresa.tomador_empresa.localidad,
        empresa_telefono: this.seguro.empresa.tomador_empresa.telefono,
        empresa_email: this.seguro.empresa.tomador_empresa.email,
        empresa_nombre_pral: this.seguro.empresa.conductor_pral.nombre,
        empresa_apellido_1: this.seguro.empresa.conductor_pral.apellido_1,
        empresa_apellido_2: this.seguro.empresa.conductor_pral.apellido_2,
        empresa_dni_tipo: this.seguro.empresa.conductor_pral.dni_tipo,
        empresa_dni: this.seguro.empresa.conductor_pral.dni,
        empresa_nacimiento: this.seguro.empresa.conductor_pral.nacimiento,
        empresa_carnet: this.seguro.empresa.conductor_pral.carnet,


        tomador_nombre: this.seguro.tomador.nombre,
        tomador_apellido_1: this.seguro.tomador.apellido_1,
        tomador_apellido_2: this.seguro.tomador.apellido_2,
        tomador_dni_tipo: this.seguro.tomador.dni_tipo,
        tomador_dni: this.seguro.tomador.dni,
        tomador_nacimiento: this.seguro.tomador.nacimiento,
        tomador_carnet: this.seguro.tomador.carnet,
        tomador_direccion_texto: this.seguro.tomador.direccion_texto,
        tomador_tipo_de_via: this.seguro.tomador.tipo_de_via,
        tomador_direccion_numero: this.seguro.tomador.direccion_numero,
        tomador_direccion_piso: this.seguro.tomador.direccion_piso,
        tomador_direccion_puerta: this.seguro.tomador.direccion_puerta,
        tomador_postal: this.seguro.tomador.postal,
        tomador_localidad: this.seguro.tomador.localidad,
        tomador_telefono: this.seguro.tomador.telefono,
        tomador_email: this.seguro.tomador.email,
        
        propietario_nombre: this.seguro.propietario.nombre,
        propietario_apellido_1: this.seguro.propietario.apellido_1,
        propietario_apellido_2: this.seguro.propietario.apellido_2,
        propietario_dni_tipo: this.seguro.propietario.dni_tipo,
        propietario_dni: this.seguro.propietario.dni,
        propietario_nacimiento: this.seguro.propietario.nacimiento,
        propietario_carnet: this.seguro.propietario.carnet,
        propietario_direccion_texto: this.seguro.propietario.direccion_texto,
        propietario_tipo_de_via: this.seguro.propietario.tipo_de_via,
        propietario_direccion_numero: this.seguro.propietario.direccion_numero,
        propietario_direccion_piso: this.seguro.propietario.direccion_piso,
        propietario_direccion_puerta: this.seguro.propietario.direccion_puerta,
        propietario_postal: this.seguro.propietario.postal,
        propietario_localidad: this.seguro.propietario.localidad,
        propietario_telefono: this.seguro.propietario.telefono,
        propietario_email: this.seguro.propietario.email,

        conductor_1_nombre: this.seguro.conductor_1.nombre,
        conductor_1_apellido_1: this.seguro.conductor_1.apellido_1,
        conductor_1_apellido_2: this.seguro.conductor_1.apellido_2,
        conductor_1_dni_tipo: this.seguro.conductor_1.dni_tipo,
        conductor_1_dni: this.seguro.conductor_1.dni,
        conductor_1_nacimiento: this.seguro.conductor_1.nacimiento,
        conductor_1_carnet: this.seguro.conductor_1.carnet,

        conductor_2_nombre: this.seguro.conductor_2.nombre,
        conductor_2_apellido_1: this.seguro.conductor_2.apellido_1,
        conductor_2_apellido_2: this.seguro.conductor_2.apellido_2,
        conductor_2_dni_tipo: this.seguro.conductor_2.dni_tipo,
        conductor_2_dni: this.seguro.conductor_2.dni,
        conductor_2_nacimiento: this.seguro.conductor_2.nacimiento,
        conductor_2_carnet: this.seguro.conductor_2.carnet,

        conductor_3_nombre: this.seguro.conductor_3.nombre,
        conductor_3_apellido_1: this.seguro.conductor_3.apellido_1,
        conductor_3_apellido_2: this.seguro.conductor_3.apellido_2,
        conductor_3_dni_tipo: this.seguro.conductor_3.dni_tipo,
        conductor_3_dni: this.seguro.conductor_3.dni,
        conductor_3_nacimiento: this.seguro.conductor_3.nacimiento,
        conductor_3_carnet: this.seguro.conductor_3.carnet,

        conductor_4_nombre: this.seguro.conductor_4.nombre,
        conductor_4_apellido_1: this.seguro.conductor_4.apellido_1,
        conductor_4_apellido_2: this.seguro.conductor_4.apellido_2,
        conductor_4_dni_tipo: this.seguro.conductor_4.dni_tipo,
        conductor_4_dni: this.seguro.conductor_4.dni,
        conductor_4_nacimiento: this.seguro.conductor_4.nacimiento,
        conductor_4_carnet: this.seguro.conductor_4.carnet,

        conductor_5_nombre: this.seguro.conductor_5.nombre,
        conductor_5_apellido_1: this.seguro.conductor_5.apellido_1,
        conductor_5_apellido_2: this.seguro.conductor_5.apellido_2,
        conductor_5_dni_tipo: this.seguro.conductor_5.dni_tipo,
        conductor_5_dni: this.seguro.conductor_5.dni,
        conductor_5_nacimiento: this.seguro.conductor_5.nacimiento,
        conductor_5_carnet: this.seguro.conductor_5.carnet,
        como_conocido: this.seguro.como_conocido,
        como_conocido_otros: this.seguro.como_conocido_otros,
        tiene_codigo_promocional: this.seguro.tiene_codigo_promocional,
        codigo_promocional: this.seguro.codigo_promocional,
        formulari: 'Conficaravan',


      })
      .then(function (response) {
          console.log(response);
      })
      .then(()=>{
        this.$gtag.event('confirmar_enviar_datos', {
          'event_name': 'confirmar_enviar_datos'
        })
      })
      .catch(function (error) {
          console.log(error);
      });

      document.getElementById('resumen').classList.add('d-none');
      document.getElementById('cierre').classList.remove('d-none');
      return true;

    },
    showDiv(a){
      var mydiv = document.getElementById(a);
      
      if(a == 'propietario' && this.radio_propietario == true){
        mydiv.classList.remove('d-none');
      }else if(a == 'propietario' && this.checked_propietario == false){
        mydiv.classList.add('d-none');
      }else if(a == 'conductor' && this.checked_conductor == true){
        mydiv.classList.remove('d-none');
      }else if(a == 'conductor' && this.checked_conductor == false){
        mydiv.classList.add('d-none');
      }

    },
    nextMiniDiv(a){
      var mydiv = document.getElementById(a);
      var valor = this['radio_' + a];

      var ad_2 = document.getElementById('conductor_adicional_2');
      var ad_3 = document.getElementById('conductor_adicional_3');
      var ad_4 = document.getElementById('conductor_adicional_4');
      var ad_5 = document.getElementById('conductor_adicional_5');

      if(valor == 1){
        mydiv.classList.remove('d-none');
      }else if(valor == 0){
        mydiv.classList.add('d-none');
      }

      if(valor == 0 && a == 'conductor_adicional_1' && this.radio_conductor_adicional_1 == 0){
        this.radio_conductor_adicional_2 = 0;
        ad_2.classList.add('d-none');
        this.radio_conductor_adicional_3 = 0;
        ad_3.classList.add('d-none');
        this.radio_conductor_adicional_4 = 0;
        ad_4.classList.add('d-none');
        this.radio_conductor_adicional_5 = 0;
        ad_5.classList.add('d-none');
      }else if(valor == 0 && a == 'conductor_adicional_2' && this.radio_conductor_adicional_2 == 0){
        this.radio_conductor_adicional_3 = 0;
        ad_3.classList.add('d-none');
        this.radio_conductor_adicional_4 = 0;
        ad_4.classList.add('d-none');
        this.radio_conductor_adicional_5 = 0;
        ad_5.classList.add('d-none');
      }else if(valor == 0 && a == 'conductor_adicional_3' && this.radio_conductor_adicional_3 == 0){
        this.radio_conductor_adicional_4 = 0;
        ad_4.classList.add('d-none');
        this.radio_conductor_adicional_5 = 0;
        ad_5.classList.add('d-none');
      }else if(valor == 0 && a == 'conductor_adicional_4' && this.radio_conductor_adicional_4 == 0){
        this.radio_conductor_adicional_5 = 0;
        ad_5.classList.add('d-none');
      }
      
    },
    empresaToggle(a,b){
      
      var empresadiv = document.getElementById(a);
      var tomadordiv = document.getElementById(b);
      var valor = this['radio_empresa'];

      console.log(this.submitted2);

      if(valor == 1){
        empresadiv.classList.remove('d-none');
        tomadordiv.classList.add('d-none');
        this.seguro.tomador.nombre = '';
        this.seguro.tomador.apellido_1 = '';
        this.seguro.tomador.apellido_2 = '';
        this.seguro.tomador.dni_tipo = '';
        this.seguro.tomador.dni = '';
        this.seguro.tomador.nacimiento = '';
        this.seguro.tomador.carnet = '';
        this.seguro.tomador.direccion_texto = '';
        this.seguro.tomador.tipo_de_via = '';
        this.seguro.tomador.direccion_numero = '';
        this.seguro.tomador.direccion_piso = '';
        this.seguro.tomador.direccion_piso = '';
        this.seguro.tomador.postal = '';
        this.seguro.tomador.localidad = '';
        this.seguro.tomador.telefono = '';
        this.seguro.tomador.email = '';

        this.submitted2 = false;

        console.log(this.seguro.tomador);

      }else if(valor == 0){
        empresadiv.classList.add('d-none');
        tomadordiv.classList.remove('d-none');
        this.seguro.empresa.tomador_empresa.nombre = '';
        this.seguro.empresa.tomador_empresa.cif = '';
        this.seguro.empresa.tomador_empresa.direccion_texto = '';
        this.seguro.empresa.tomador_empresa.tipo_de_via = '';
        this.seguro.empresa.tomador_empresa.direccion_numero = '';
        this.seguro.empresa.tomador_empresa.direccion_piso = '';
        this.seguro.empresa.tomador_empresa.direccion_puerta = '';
        this.seguro.empresa.tomador_empresa.postal = '';
        this.seguro.empresa.tomador_empresa.localidad = '';
        this.seguro.empresa.tomador_empresa.telefono = '';
        this.seguro.empresa.tomador_empresa.email = '';
        
        this.seguro.empresa.conductor_pral.nombre = '';
        this.seguro.empresa.conductor_pral.apellido_1 = '';
        this.seguro.empresa.conductor_pral.apellido_2 = '';
        this.seguro.empresa.conductor_pral.dni_tipo = '';
        this.seguro.empresa.conductor_pral.dni = '';
        this.seguro.empresa.conductor_pral.nacimiento = '';
        this.seguro.empresa.conductor_pral.carnet = '';

        this.submitted2 = false;

        console.log(this.seguro.empresa.tomador_empresa);
      }
    },
    nextDiv(a){
      
      var mydiv = document.getElementById(a);
      var alldivs = document.getElementsByClassName('alldivs');
      
      for (let i = 0; i < alldivs.length; i++) {
        alldivs[i].classList.add('d-none');
      }

      if(a == 'tomador'){
        this.$bvModal.show('alert_menores');
      }
      
      mydiv.classList.remove('d-none');
    },
    edad(){
      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth() + 1;
      var yyyy = today.getFullYear();
      if (dd < 10) {
        dd = '0' + dd;
      }
      if (mm < 10) {
        mm = '0' + mm;
      }
      var new_year = yyyy - 25;
      var newDate = new_year + '-' + mm + '-' + dd;
      var year25 = new Date(newDate).getTime();

      var fecha1 = new Date(this.seguro.propietario.nacimiento).getTime();

      if (fecha1 < year25){
        this.advice_25 = false;
      }else if(fecha1 > year25){
        this.advice_25 = true;
      }
      console.log('edad');
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .form-label{
    margin-bottom: 1px;
  }

  /* div#navbarSupportedContent{
    display: none !important;
  } */

div#navbarSupportedContent{
    display: none;
}

.error-border{
  border: 1px solid red;
}

</style>

<template>
  <div id="asistencia">
    <div class="container-fluid">
      <div class="row align-items-top justify-content-center">
          <div class="col-12 bk_contratar p-4 text-center">
      
              <h1 class="text-white mt-3">Asistencia</h1>

          </div>
      </div>
      <div class="container">

        <div class="row align-items-top justify-content-center mt-4">

          <div class="col-12">
            <h3 class="color">Siniestros</h3>
          </div>

        </div>
        <div class="grid-precio-final">

          <div class="col-12 bk_gray rounded p-4 mb-2">
            <div class="">
              <h4 class="color mb-3">He sufrido un accidente / avería</h4>
              <p>Llama gratuitamente a Asistencia en <span class="text-nowrap">viaje 24h</span></p>
              <p>Desde España: <span class="text-nowrap">900 40 40 28</span></p>
              <p>Desde el extranjero: <span class="text-nowrap">+34 93 495 51 25</span></p>
            </div>
          </div>

          <div class="col-12 bk_gray rounded p-4 mb-2">
            <div class="">
              <h4 class="color mb-3">Se me ha roto un cristal y estoy de viaje</h4>
              <p class="text-center">Talleres reparadores de cristales especializados:</p>
              <p class="text-center">
                <img src="../assets/img/carglass_icon.png" class="img-fluid" style="max-height: 80px;">
              </p>
              <p class="text-center">Atención 24h: <span class="text-nowrap">902 107 343</span></p>
              <p class="text-center"><a href="https://www.carglass.es/" target="_blank">Carglass.es</a></p>
            </div>
          </div>

          <div class="col-12 bk_gray rounded p-4 mb-2">
            <div class="">
              <h4 class="color mb-3">Quiero declarar i/o conocer el estado del siniestro</h4>
              <p>Si deseas declarar o saber el estado de tu siniestro puedes utilizar cualquiera de estas 3 vías:</p>
              <p>• Llamando GRATUITAMENTE a la compañía: <span class="text-nowrap">900 101 369</span></p>
              <p>• Llamando a CONFICARAVAN: <span class="text-nowrap">93 889 29 69</span></p>
              <p>• Si lo prefieres, envía un correo a <a href="mailto:siniestros@conficaravan.com" >siniestros@conficaravan.com</a></p>
            </div>
          </div>

        </div>

        <div class="row align-items-center justify-content-center mb-3 mt-3">

          <div class="col-12 mb-4 mt-4">
            <h3 class="color">Recomendaciones en caso de siniestro</h3>
            <p>Has tenido un accidente con tu Autocaravana o Camper en la carretera. No te preocupes, estamos contigo. Aquí te resumimos los pasos a seguir para que todo se solucione cuanto antes.</p>
            <ul class="">
              <li>Ponte el chaleco reflectante.</li>
              <li>Aparta el vehículo al arcén.</li>
              <li>Pon los triángulos de emergencia.</li>
              <li>Si hay lesionados llama al 112.</li>
              <li>Si algún coche abandona el siniestro, apunta matrícula, marca y modelo.</li>
              <li>Si hay testigos, apunta nombres, apellidos, direcciones y teléfonos.</li>
              <li>Si tienes cámara y las condiciones de seguridad lo permiten, haz fotos a la situación de los vehículos accidentados.</li>
              <li>Apunta matrícula, marca y modelo de los vehículos implicados.</li>
              <li>Si hay discrepancias con los implicados, llama a la Policía y espérala.</li>
              <li>Rellena el parte. Asegúrate de que lo firmáis las dos partes implicadas y os quedáis una copia cada uno.</li>
              <li>A partir de este momento, no modifiques nada en el formulario. Si tienes que poner alguna aclaración, hazlo en el reverso.</li>
              <li>Acuérdate de que tienes 7 días máximos para comunicar los detalles del siniestro.</li>
            </ul>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Asistencia',
  mounted: function () {
    window.scrollTo( 0, 0 );  
  },
  
}
</script>

<template>
  <div id="Home">

    <!-- MODAL Precio -->
    <b-modal id="precio-form" centered size="lg" :hide-footer="true" :hide-header="true">
      
      <div class="text-center">
        <div id="precio-form-div-tit">
          <h3 class="color">Calcula tu seguro en menos de 1 minuto</h3>
          <p> Te enviamos por email tu precio. </p>
        </div>
        <div id="precio-form-div" class="bk_gray rounded">
          
          <!-- Escoge tu vehiculo -->
          <div id="precio-1" class="p-2 precio_box">
            <p class="fs-4 mb-1">Escoge tu vehículo</p>
            <div class="grid-precio p-2">
              <div class="box_2" @click="next('precio-1','precio-2','1')">
                <img src="../assets/img/1_1.png" class="img-fluid">
                <p class="small color">Campers originales o furgonetas camperizadas</p>
              </div>
              <div class="box_2" @click="next('precio-1','precio-2', '2')">
                <img src="../assets/img/1_2.png" class="img-fluid">
                <p class="small color">Integrales, capuchinas y perfiladas</p>
              </div>
            </div>
          </div>


          <!-- Valor vehículo -->
          <div id="precio-2" class="p-2 precio_box d-none">
            <p class="fs-4 mb-1">¿Dinos el valor del vehículo?</p>
            <p class="small">Recuerda que el valor incluye los elementos fijos, no extraíbles, instalados de fábrica o posteriormente sobre coste por el comprador y avalados por tráfico.</p>
            <div class="grid-precio-final justify-content-center p-2">
              <div class="box_2" @click="next('precio-2','precio-3','1')">
                <img src="../assets/img/new/hasta_40mil.png" class="img-fluid" >  
              </div>
              <div class="box_2" @click="next('precio-2','precio-3','2')">
                <img src="../assets/img/new/hasta_60mil.png" class="img-fluid" >  
              </div>
              <div class="box_2" @click="next('precio-2','precio-3','3')">
                <img src="../assets/img/new/hasta_80mil.png" class="img-fluid" >  
              </div>
              <div class="box_2" @click="next('precio-2','precio-3','4')">
                <img src="../assets/img/new/hasta_100mil.png" class="img-fluid" >  
              </div>

              <!-- <div class="box_2" @click="next('precio-2','precio-3','5')">
                <img src="../assets/img/new/hasta_150mil.png" class="img-fluid" >  
              </div> -->

              <div class="box_2">
                <router-link to="/contratar" class="color"><span @click="gtagClick('mas_60000')"></span>
                  <img src="../assets/img/new/hasta_150mil.png" class="img-fluid" > 
                </router-link> 
              </div>

              <div class="box_2">
                <router-link to="/contratar" class="color"><span @click="gtagClick('mas_60000')"></span>
                  <img src="../assets/img/new/mas_de_150mil.png" class="img-fluid" > 
                </router-link> 
              </div>
              
            </div>
            <!-- <p class="text-center fs-4">Mi vehículo tiene un valor superior a los 150.000 €</p> -->

          </div>  


          <!-- POTENCIA -->
          <div id="precio-3" class="p-2 precio_box d-none">
            <p class="fs-4 mb-1" v-if="precio.precio_1 == 1">¿Y qué potencia tiene tu camper?</p>
            <p class="fs-4 mb-1" v-else-if="precio.precio_1 == 2">¿Y qué potencia tiene tu autocaravana?</p>

            <div class="grid-precio p-2">

              <!-- 120 cv -->
              <div class="box_2" v-if="precio.potencia == 120" @click="next('precio-3','precio-4','1')">
                <img src="../assets/img/new/hasta_120cv.png" class="img-fluid" >  
              </div>
              <div class="box_2" v-if="precio.potencia == 120" @click="next('precio-3','precio-4','2')">
                <img src="../assets/img/new/mas_de_120cv.png" class="img-fluid" >  
              </div>

              <!-- 140 cv -->
              <div class="box_2" v-if="precio.potencia == 140" @click="next('precio-3','precio-4','3')">
                <img src="../assets/img/new/hasta_140cv.png" class="img-fluid" >  
              </div>
              <div class="box_2" v-if="precio.potencia == 140"  @click="next('precio-3','precio-4','4')">
                <img src="../assets/img/new/mas_de_140cv.png" class="img-fluid" >  
              </div>

              <!-- 160 -->
              <div class="box_2" v-if="precio.potencia == 160" @click="next('precio-3','precio-4','5')">
                <img src="../assets/img/new/hasta_160cv.png" class="img-fluid" >  
              </div>
              <div class="box_2" v-if="precio.potencia == 160" @click="next('precio-3','precio-4','6')">
                <img src="../assets/img/new/mas_de_160cv.png" class="img-fluid" >  
              </div>

            </div>

          </div>



          <!-- Eleccion final -->
          <div id="precio-4" class="p-2 precio_box d-none">
            <p class="mt-3">Esta es tu elección</p>
            <div class="grid-modal-precio-final p-2">
              <div class="box_2_static">
                <img :src="precio.img_1" class="img-fluid">  
              </div>
              <div class="box_2_static">
                <img :src="precio.img_2" class="img-fluid">  
              </div>
              <div class="box_2_static">
                <img :src="precio.img_3" class="img-fluid">
              </div>
            </div>
            <p class="mt-3">Y el último paso</p>
            <div class="p-3 pt-0">

               <div class="input-group input-group-lg mb-3">
                <span class="input-group-text bk_color">Nombre</span>
                <input type="text" id="nombre" class="form-control" placeholder="Nombre" aria-label="Nombre" aria-describedby="Nombre" v-model="precio.nombre">
              </div>

              <div class="input-group input-group-lg mb-3">
                <span class="input-group-text bk_color" style="padding-right: 38px;">Email</span>
                <input type="text" id="email" class="form-control" placeholder="email@ejemplo.com" aria-label="Email" aria-describedby="Email" v-model="precio.email">
              </div> 

              <!-- <div class="mb-3 form-check text-start">
                <input type="checkbox" class="form-check-input" id="exampleCheck1" v-model="precio.consent">
                <label class="form-check-label" for="exampleCheck1">Autorizo a Confisergur a enviarme un email a la dirección proporcionada</label>
              </div> -->

              <div class="mb-3 form-check text-start">
                <input type="checkbox" class="form-check-input" @change="samplefunction(1,$event)" id="input_privacitat" v-model="privacitat_acceptada">
                <label class="form-check-label" for="input_privacitat"> He leído y acepto la <a href="/privacidad" target="_blank"> Política de privacidad</a> </label>
              </div>


              <div class="mb-3 form-check text-start">
                <input type="checkbox" class="form-check-input" id="input_comunicacions" v-model="check_input_comunicacions">
                <label class="form-check-label" for="input_comunicacions"> Quiero que me avisen si mi precio mejora. Deseo recibir comunicaciones comerciales relacionadas.</label>
              </div>

              <div class="text-center">
                <p class="error text-danger p-0 m-0" v-if="submitted && !$v.precio.email.required">Es necesario un email para enviar el precio de tu seguro</p>
                <p class="error text-danger" v-if="submitted && !$v.precio.consent.required">Necesitamos tu autorización para enviarte un email con tu precio</p>
              </div>

              <div class="form-check text-center" v-if="(!mostrarInputSend)">
                <button class="btn btn-primary btn-lg" disabled style="border: 1px solid #8c173847; background: #8c1738d9 !important">Enviar</button>
              </div>

              <div class="form-check text-center"  v-if="(mostrarInputSend)">
                <button class="btn btn-primary btn-lg" @click="next('precio-4','precio-4','precio-4')">Enviar</button>
              </div>

            </div>
          </div>

        </div>

        <!-- Modal final  -->
        <div id="precio-form-div-ok" class="d-none">
          <h3 class="color">¡Ya tienes tu precio!</h3>
          <p> Revisa tu correo electronico para conocer el precio de tu seguro de autocaravana o camper.</p>
          <p> Gracias por confiar en Confisergur. </p> <br>
        </div>

        <!-- Botons per interactuar, tancar i tornar endarrera -->
        <div class="mt-3">
          <p>
            <a class="color float-start" @click="$bvModal.hide('precio-form');"> Cerrar ventana</a>
            <a class="color float-end" @click="restart()" > &lt;&lt; Volver a empezar</a>
          </p>
        </div>
      </div>
      
    </b-modal>
    


    <!-- End Modal -->
    <b-modal id="ayuda-form" centered size="lg" :hide-footer="true" :hide-header="true">
      
      <div id="ayuda-form-div">
        <div class="row align-items-center justify-content-center p-3">
          <div class="col-12 text-center">
            <h3 class="color">Contáctanos</h3>
            <p>Completa el formulario y resolvemos tus dudas.</p>
          </div>
          <div class="col-12 bk_gray rounded p-3 text-start">
            <div class="row align-items-center justify-content-center">
              <div class="col-12">
                <div class="mb-3">
                  <label for="ayuda-name" class="form-label">Nombre <span class="color">*</span></label>
                  <input type="text" class="form-control" id="ayuda-name" v-model="ayuda.name">
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <div class="mb-3">
                  <label for="ayuda-email" class="form-label">Email <span class="color">*</span></label>
                  <input type="email" class="form-control" id="ayuda-email" v-model="ayuda.email">
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <div class="mb-3">
                  <label for="ayuda-phone" class="form-label">Teléfono</label>
                  <input type="email" class="form-control" id="ayuda-phone" v-model="ayuda.phone">
                </div>
              </div>
              <div class="col-12">
                <div class="mb-3">
                  <label for="ayuda-subject" class="form-label">Asunto</label>
                  <input type="email" class="form-control" id="ayuda-subject" v-model="ayuda.subject">
                </div>
              </div>
              <div class="col-12">
                <div class="mb-3">
                  <label for="ayuda-message" class="form-label">Mensaje <span class="color">*</span></label>
                  <textarea class="form-control" id="ayuda-message" rows="3" v-model="ayuda.message"></textarea>
                </div>
                <div class="mb-3 form-check">
                  <input type="checkbox" class="form-check-input" id="ayuda-checkbox" v-model="ayuda.checked">
                  <label class="form-check-label" for="ayuda-checkbox"> He leído y acepto la <a href="/privacidad" target="_blank">Política de privacidad</a> </label>
                </div>
              </div>
              <div class="col-12">
                <div class="text-start">
                  <p class="small color">* Los campos marcados con asterísco son obligatorios</p>
                </div>
              </div>
              <div class="col-12 text-center" id="ayuda-error">
                <div class="error" v-if="ayuda.submitted && !$v.ayuda.required">Faltan campos por completar</div>
              </div>
              <div class="col-12">
                <div class="text-center">
                  <button class="btn btn-primary" @click="ayuda_form()">Enviar</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="ayuda-form-div-ok" class="d-none text-center">
        <h3 class="color">Gracias por confiar en Conficaravan</h3>
        <p>Pronto contactaremos contigo. Un saludo.</p>
      </div>
      
    </b-modal>



    <!-- MODAL Franquicias agrupadas -->
    <b-modal id="franquicias" centered size="lg" :hide-footer="true" :hide-header="true">
      
      <div class="text-center">
        <h2 class="color">Franquicias agrupadadas</h2>
        <p>En caso de daños generales sin contrario identificado, cuando se verifique que los mismos son los derivados de varios hechos, el asegurador aplicara la franquicia contratada en póliza por cada lado afectado del vehículo, aceptándose como un solo siniestro todos los daños que se ubiquen en esa parte del mismo. Quedaran excluidos en su totalidad los daños del vehículo que se hubieran producido con anterioridad a la contratación de la póliza.</p>
      </div>
      
    </b-modal>



    <!-- End Modal Franquicias agrupadas -->
    <!-- MODAL Valor declarado -->
    <b-modal id="valor" centered size="lg" :hide-footer="true" :hide-header="true">
      
      <div class="text-center">
        <h2 class="color">Valor declarado</h2>
        <p>Se hace constar en póliza el valor del vehículo asegurado en el momento de contratación, incluyendo todos los accesorios declarados. Se aportará copia de factura de compra del vehículo. En el caso de que el vehículo no sea nuevo, el valor declarado no podrá ser superior al valor de factura.</p>
      </div>
      
    </b-modal>
    <!-- End Modal Valor declarado -->



    <!-- Contingut de la pàgina -->
    <div class="container-fluid">
      <div class="row align-items-center justify-content-center bk_top">
        <div class="col-12 offset-sm-4 col-sm-8 offset-md-6 col-md-6 text-center">
          <img src="../assets/img/conficaravan_logo.png" class="img-fluid mb-3">
          <h1 class="color fw-bold text_shadow mt-3">El mejor seguro para<br>autocaravanas y campers</h1>
          <p class="text-center mt-4">
            <button class="btn btn-primary btn-lg" @click="calcular_precio_gtag()"  v-b-modal.precio-form>Calcular Precio</button><br>
          </p>
        </div>
      </div>
      <div class="container">

        <!-- Bloque Ventajas captacion -->
        
        <div class="row align-items-top justify-content-center mt-5 mb-5">  
          <div class="col-sm-8">
            <div class="div_img_left d-block clearfix text-center text-sm-start mb-4">
              <img src="../assets/img/ico_caravan_1.png" class="img-fluid float-sm-start" style="max-height: 100px;margin-top:-10px;">
              <h3 class="color">Producto exclusivo Conficaravan</h3>
              <p>Descubre las ventajas del pack Conficaravan con coberturas exclusivas para nuestros clientes.</p>
            </div>
            <div class="div_img_left d-block clearfix text-center text-sm-start mb-4">
              <img src="../assets/img/ico_caravan_2.png" class="img-fluid float-sm-start" style="max-height: 100px;margin-top:-10px;">
              <h3 class="color">Atención personalizada</h3>
              <p>Siempre vas a encontrar un profesional que resuelva todas tus dudas y darte soluciones.</p>
            </div>
            <div class="div_img_left d-block clearfix text-center text-sm-start mb-4">
              <img src="../assets/img/ico_caravan_3.png" class="img-fluid float-sm-start" style="max-height: 100px;margin-top:-10px;">
              <h3 class="color">Tramites rápidos y fáciles</h3>
              <p>Todos los tramites online, nosotros nos encargamos de todo.</p>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="bk_color p-4 text-center img-confi">
              <img src="../assets/img/calculadora_ico.png" class="img-fluid" style="max-height: 100px;">
              <p class="fs-4">Calcula tu seguro en menos <span class="text-nowrap">de 1 minuto</span></p>
              <p class="text-center">
                <button class="btn btn-secondary btn-lg" @click="calcular_precio_gtag()"  v-b-modal.precio-form >Calcular Precio</button>
              </p>
            </div>
          </div>
        </div>

        <!-- END Ventajas captacion -->
        <!-- Bloque Trio ventajas -->

        <div class="row align-items-center justify-content-center mt-5 mb-5 p-3 pt-4 pb-4 rounded bk_gray">
          <div class="col-12 text-center grid">
            <div class="grid-item">
              <img src="../assets/img/img_1.jpg" class="img-fluid img-confi">
              <h3 class="color mt-3 mb-3">Accesorios fijos incluídos <span class="text-nowrap">sin coste</span></h3>
              <p>Te incluimos cualquier tipo de camperización o accesorio fijo hasta 15.000€ sin coste adicional.</p>
            </div>
            <div class="grid-item">
              <img src="../assets/img/img_2.jpg" class="img-fluid img-confi">
              <h3 class="color mt-3 mb-3">Cobertura del <span class="text-nowrap">material deportivo</span></h3>
              <p>Ampliación de la cobertura de los efectos personales y el material deportivo hasta 2.000€.</p>
            </div>
            <div class="grid-item">
              <img src="../assets/img/img_3.jpg" class="img-fluid img-confi">
              <h3 class="color mt-3 mb-3">Todo tipo de lunas cubiertas <span class="text-nowrap">sin franquicia</span></h3>
              <p>Cobertura de lunas de cristal, e incluso de plástico y metacrilato sin franquicia.</p>
            </div>
          </div>
        </div
        >
        <!-- END Trio ventajas -->
        <!-- Bloque Coberturas -->

        <div class="row align-items-center justify-content-center mt-5 mb-5">
          <div class="col-sm-8 text-center order-2 order-sm-1">
            <h2 class="color">Todas las coberturas para tu Camper o Autocaravana</h2>
            <p>En Conficaravan estamos especializados en seguros para todo tipo de autocaravanas, campers y road home.</p>
            <p>Sabemos que tu autocaravana o camper es mucho más que un vehículo, es también tu hogar, por eso te damos las coberturas necesarias con el seguro Exclusivo para Autocaravanas Conficaravan.</p>
            <p class="text-center fs-5 mt-4"><router-link to="/coberturas" class="btn btn-primary btn-lg">Descubre todas las coberturas</router-link></p>
          </div>
          <div class="col-sm-4 text-center order-1 order-sm-2 pb-3 pb-sm-0">
            <img src="../assets/img/img_4.jpg" class="img-fluid img-confi">
          </div>
        </div>

        <!-- END Coberturas -->
        <!-- Bloque Modalidades -->

        <div class="row align-items-center justify-content-center mt-5 mb-5 p-3 pt-4 pb-4 rounded bk_gray">
          <div class="col-12 text-center mt-2 mb-3">
            <h3 class="color">Tu eliges la modalidad de seguro que mejor se adapta a ti</h3>
          </div>
          <div class="col-12 text-center grid">
            <div class="grid-item box_1 text-start">
              <h3 class="color">Terceros Basic</h3>
              <p class="fs-4">Desde 288 €</p>
              <p class="fst-italic">Terceros + lunas</p>
              <ul class="list-unstyled icon_list_left">
                <li><font-awesome-icon icon="check-circle" class="color" /> Responsabilidad civil obligatoria y voluntaria</li>
                <li><font-awesome-icon icon="check-circle" class="color" /> Defensa jurídica</li>
                <li><font-awesome-icon icon="check-circle" class="color" /> Asistencia en viaje ampliada</li>
                <li><font-awesome-icon icon="check-circle" class="color" /> Reclamación de daños</li>
                <li><font-awesome-icon icon="check-circle" class="color" /> Lunas (incluidos metacrilatos y claraboyas)</li>
                <li><font-awesome-icon icon="check-circle" class="color" /> Pack Conficaravan <router-link to="coberturas" style="color: #000000"><font-awesome-icon icon="sign-in-alt" class="hover_icon" style="margin-left: 0px !important"/></router-link></li>
              </ul>
              <p class="text-center mt-3">
                <button class="btn btn-primary" @click="calcular_precio_gtag()"  v-b-modal.precio-form>Calcular Precio</button>
              </p>
            </div>
            <div class="grid-item box_1 text-start">
              <h3 class="color">Terceros Complet</h3>
              <p class="fs-4">Desde 348 €</p>
              <p class="fst-italic">Tercero básico más</p>
              <ul class="list-unstyled icon_list_left">
                <li><font-awesome-icon icon="check-circle" class="color" /> Robo e incendio</li>
                <li><font-awesome-icon icon="check-circle" class="color" /> Camperización y/o accesorios fijos</li>
                <li><font-awesome-icon icon="check-circle" class="color" /> Cobertura de material deportivo</li>
                <li><font-awesome-icon icon="check-circle" class="color" /> Choque con animales</li>
                <li><font-awesome-icon icon="check-circle" class="color" /> Daños meteorológicos</li>
                <li><font-awesome-icon icon="check-circle" class="color" /> Pack Conficaravan <router-link to="coberturas" style="color: #000000"><font-awesome-icon icon="sign-in-alt" class="hover_icon" style="margin-left: 0px !important"/></router-link></li>
              </ul>
              <p class="text-center mt-3">
                <button class="btn btn-primary" @click="calcular_precio_gtag()"  v-b-modal.precio-form>Calcular Precio</button>
              </p>
            </div>
            <div class="grid-item box_1 text-start">
              <h3 class="color">Todo Riesgo</h3>
              <div class="row">
                <div class="col-6">
                  <p class="fs-4 mb-0">Desde 450 €</p>
                  <h4 class="fs-6">Franquicia 300€</h4>
                </div>
                <div class="col-6">
                  
                  <p class="fs-4 mb-0">Desde 378 €</p>
                  <h4 class="fs-6">Franquicia 600€</h4>
                </div>
              </div>

              <div class="row d-none">
                <div class="col-12">
                  <h4 class="fs-5 mb-0">Franquicia 300€</h4>
                  <!-- <p class="fs-4">Desde 413 €</p> -->
                </div>
                <div class="col-12">
                  <h4 class="fs-5 mb-0">Franquicia 600€</h4>
                  <!-- <p class="fs-4">Desde 347 €</p> -->
                </div>
              </div>
              
              <p class="fst-italic">Terceros completo más</p>
              <ul class="list-unstyled icon_list_left">
                <li><font-awesome-icon icon="check-circle" class="color" /> Daños propios del vehículo<br>(Franq. 300€/600€)</li>
                <li><font-awesome-icon icon="check-circle" class="color" /> Valor declarado  <font-awesome-icon icon="info-circle" class="hover_icon" style="margin-left: 0px !important" data-bs-toggle="button" v-b-modal.valor/></li>
                <li><font-awesome-icon icon="check-circle" class="color" /> Franquicias agrupadas <font-awesome-icon icon="info-circle" class="hover_icon" style="margin-left: 0px !important" data-bs-toggle="button" v-b-modal.franquicias/></li>
                <li><font-awesome-icon icon="check-circle" class="color" /> Pack Conficaravan <router-link to="coberturas" style="color: #000000"><font-awesome-icon icon="sign-in-alt" class="hover_icon" style="margin-left: 0px !important"/></router-link></li>
              </ul>
              <p class="text-center mt-3">
                <button class="btn btn-primary" @click="calcular_precio_gtag()"  v-b-modal.precio-form>Calcular Precio</button>
              </p>
            </div>
          </div>
        </div>

        <!-- END Modalidades -->
        <!-- Bloque Ayuda -->

        <div class="row align-items-center justify-content-center mt-5 mb-5">
          <div class="col-sm-8 text-center order-2 order-sm-1">
            <h2 class="color">¿Necesitas ayuda?</h2>
            <p>Explícanos tu consulta. Estaremos encantados de ayudarte.</p>
            <p class="fs-5 mt-4"><button class="btn btn-primary btn-lg" v-b-modal.ayuda-form>Contactar</button></p>
          </div>
          <div class="col-sm-4 text-center order-1 order-sm-2 pb-3 pb-sm-0">
            <img src="../assets/img/img_6_bis.jpg" class="img-fluid img-confi">
          </div>
        </div>

        <!-- END Ayuda -->
        <!-- Bloque Confirmar precio -->

        <div class="row align-items-center justify-content-center mt-5 mb-5 p-3 pt-4 pb-4 rounded">
          <div class="col-12 text-center">
            <h2 class="color">¿Ya has calculado tu seguro Conficaravan?</h2>
            <p class="text-center mt-3"><router-link to="/confirmar" class="btn btn-primary btn-lg mt-2">Confirma tu precio</router-link></p>
          </div>
        </div>

        <!-- END Confirmar precio -->
        <!-- Bloque Empresas -->

        <div class="row align-items-center justify-content-center mt-5 mb-5 p-3 pt-4 pb-4 rounded bk_gray d-none">
          <div class="col-sm-4">
            <img src="../assets/img/img_5.jpg" class="img-fluid img-confi">
          </div>
          <div class="col-sm-8">
            <h3 class="color">¿Eres una empresa de alquiler de autocaravanas o campers?</h3>
            <p>¿Buscas un seguro para poder alquilar autocaravanas y campers con total tranquilidad?</p>
            <p>Déjanos tus datos y un asesor te llamará para informarte.</p>
            <div class="text-center p-3 bk_color rounded">
              <form>
                <div class="row align-items-center justify-content-center">
                  <div class="col-12 col-sm-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text" id="business_name">Nombre</span>
                      <input type="text" class="form-control" aria-label="Nombre" aria-describedby="business_name" v-model="form.name">
                    </div>
                  </div>
                  <div class="col-12 col-sm-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text" id="business_phone">Teléfono</span>
                      <input type="tel" class="form-control" aria-label="Teléfono" aria-describedby="business_phone" v-model="form.phone">
                    </div>
                  </div>
                  <div class="col-12 col-sm-12">
                    <div class="input-group mb-3">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="business_check" v-model="form.checked">
                        <label class="form-check-label" for="inlineFormCheck">Doy permiso a la empresa Confisergur a ponerse en contacto conmigo por vía telefónica.</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 text-center">
                    <button type="button" class="btn btn-secondary" @click="business_form()">Enviar</button>
                  </div>
                </div>
                
              </form>

            </div>
          </div>
        </div>

        <!-- END Empresas -->

      </div>

    </div>

    

  </div>
</template>

<script>
// import { Splide, SplideSlide } from '@splidejs/vue-splide';
import { required, email } from "vuelidate/lib/validators";
import axios from 'axios';

export default {
  name: 'Home',
  components: {
    // Splide,
    // SplideSlide
  },
  validations: {
    precio: {
      precio_1: {
        required,
      },
      precio_2: {
        required,
      },
      precio_3: {
        required,
      },
      img_1: {
        required,
      },
      img_2: {
        required,
      },
      img_3: {
        required,
      },
      email: {
        required,
        email
      },
      consent: {
        required,
      }
    },
    form: {
      name: {
        required,
      },
      phone: {
        required,
      },
      checked: {
        required,
      }
    },
    ayuda: {
        name: {
          required
        },
        email: {
          required
        },
        message: {
          required
        },
        checked: {
          required
        }
      },
  },
  data() {
    return{
      privacitat_acceptada: false,
      check_input_comunicacions: false,
      submitted: false,
      mostrarInputSend: false,
      amagarInputOtros: true,
      amagarInputCodigo : true,
      precio: {
        precio_1: '',
        precio_2: '',
        precio_3: '',
        img_1: '',
        img_2: '',
        img_3: '',
        email: '',
        consent: true,
        potencia: null,
      },
      form: {
        name: '',
        phone: '',
        checked: false,
      },
      ayuda: {
        submitted: false,
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
        checked: false,
      },
      options: {
        type: 'loop',
        autoplay: true,
        rewind : true,
        pauseOnHover: true,
        perPage: 3,
        perMove: 1,
        gap: '3rem',
        pagination: true,
        arrows: false,
        breakpoints: {
          600: {
            perPage: 1,
          },
        }
      }
    }
  },
  mounted: function() {
    if(this.$route.query.m){
      this.$bvModal.show('precio-form');
    }

    window.scrollTo( 0, 0 );  
  },
  methods: {

    getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
          
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    },
    comprovarValueConocido(){
      var id_conocido = this.precio.como_conocido
      if(id_conocido == 4){
          this.amagarInputOtros = false;
      }else{
         this.amagarInputOtros = true;
      }
    },
    samplefunction : function(value,event) {
      if (event.target.checked) {
        this.mostrarInputSend = true;
        console.log('selected');
      }else{
        this.mostrarInputSend = false;
      }
    },

    comprovarCodigoPromocional(){
      var tiene_codigo_promocional = this.precio.tiene_codigo_promocional
      if(tiene_codigo_promocional == 1){
          this.amagarInputCodigo = false;
      }else{
         this.amagarInputCodigo = true;
      }
    },

    next(o,e,value){
      var precio_div = document.getElementsByClassName('precio_box');
      var precio_div_new = document.getElementById(e);
      
      if(o == 'precio-1'){
        this.precio.precio_1 = value;
        this.precio.img_1 = 'https://conficaravan.com/img_mail/1_' + value + '.png';
      }else if(o == 'precio-2'){
        this.precio.precio_2 = value;

        if(value == 1){
          this.precio.img_2 = "https://conficaravan.com/img_mail/hasta_40mil.png";
          this.precio.potencia = 120;
        }else if(value == 2){
          this.precio.img_2 = "https://conficaravan.com/img_mail/hasta_60mil.png";
          this.precio.potencia = 140;
        }else  if(value == 3){
          this.precio.img_2 = "https://conficaravan.com/img_mail/hasta_80mil.png";
          this.precio.potencia = 140;
        }else if(value == 4){
          this.precio.img_2 = "https://conficaravan.com/img_mail/hasta_100mil.png";
          this.precio.potencia = 160;
        }else if(value == 5){
          this.precio.img_2 = "https://conficaravan.com/img_mail/hasta_150mil.png";
          this.precio.potencia = 160;
        }

      }else if(o == 'precio-3'){
        this.precio.precio_3 = value;

        if(value == 1){
          this.precio.img_3 = "https://conficaravan.com/img_mail/hasta_120cv.png";
        }else if(value == 2){
          this.precio.img_3 = "https://conficaravan.com/img_mail/mas_de_120cv.png";
        }else  if(value == 3){
          this.precio.img_3 = "https://conficaravan.com/img_mail/hasta_140cv.png";
        }else if(value == 4){
          this.precio.img_3 = "https://conficaravan.com/img_mail/mas_de_140cv.png";
        }else if(value == 5){
          this.precio.img_3 = "https://conficaravan.com/img_mail/hasta_160cv.png";
        }else if(value == 6){
          this.precio.img_3 = "https://conficaravan.com/img_mail/mas_de_160cv.png";
        }

      }else if(o == 'precio-4'){
        
        this.submitted = true;
        
        var email = document.getElementById('email');
        this.precio.email = email.value;
        var nombre = document.getElementById('nombre');
        this.precio.nombre = nombre.value;

        // console.log(this.precio.email, this.precio.nombre, this.precio.precio_1, this.precio.precio_2, this.precio.precio_3);
        
        this.$v.$touch();

        if(this.$v.precio.$invalid || !this.$v.precio.consent.$model === true){
            return false;
        }else{
          // upload to mail.php
          axios.post('https://conficaravan.com/code/mail.php', {
            email: this.precio.email,
            nombre: this.precio.nombre,
            precio_1: this.precio.precio_1,
            precio_2: this.precio.precio_2,
            precio_3: this.precio.precio_3,
            img_1: this.precio.img_1,
            img_2: this.precio.img_2,
            img_3: this.precio.img_3,
            comunicacions: this.check_input_comunicacions,
          }).then(function (response) {
            console.log(response);
          }).catch(function (error) {
            console.log(error);
          });

          // upload to up_db.php
          axios.post('https://conficaravan.com/code/up_db.php', {
            email: this.precio.email,
            nombre: this.precio.nombre,
            precio_1: this.precio.precio_1,
            precio_2: this.precio.precio_2,
            precio_3: this.precio.precio_3,
            comunicacions: this.check_input_comunicacions,
          }).then(function (response) {
            console.log(response);
          }).catch(function (error) {
            console.log(error);
          });
          
          var div_form = document.getElementById('precio-form-div');
          var div_form_tit = document.getElementById('precio-form-div-tit');
          var div_form_ok = document.getElementById('precio-form-div-ok');

          div_form.classList.add('d-none');
          div_form_tit.classList.add('d-none');
          div_form_ok.classList.remove('d-none');

          // this.$gtag.event('click_form_one', {
          //   'event_name': 'form_one'
          // })

          this.$gtag.event('calcular_enviar_datos', {
            'event_name': 'calcular_enviar_datos'
          })
          
        }
        
      }else{
        console.log('Algo pasa Baby!');
      }
      
      if(value == '1'){
        this.$gtag.event('calcular_camper', {
          'event_name': 'calcular_camper'
        })
      }else if(value == '2'){
        this.$gtag.event('calcular_autocaravana', {
          'event_name': 'calcular_autocaravana'
        })
      }
      
      var i = 0;
      for (i = 0; precio_div.length > i; i++){
        precio_div[i].classList.add('d-none');
      }

      precio_div_new.classList.remove('d-none');
    },

    gtagClick(a){
      this.$gtag.event(a, {
        'event_name': 'calcular_precio'
      })
    },
    business_form(){
      var name = this.$v.form.name.$model;
      var phone = this.$v.form.phone.$model;
      var checked = this.$v.form.checked.$model;

      if(this.$v.form.$invalid || !this.$v.form.checked.$model === true){
        console.log('What r u doing here?');
      }else{
        console.log(name, phone, checked);
        axios.post('https://conficaravan.com/code/business_contact.php', {
          name: name,
          phone: phone
        }).then(function (response) {
          console.log(response);
        }).catch(function (error) {
          console.log(error);
        });
      }
      
    },
    ayuda_form(){
      this.ayuda.submitted = true;

      var name = this.$v.ayuda.$model.name;
      var email = this.$v.ayuda.$model.email;
      var phone = this.$v.ayuda.$model.phone;
      var subject = this.$v.ayuda.$model.subject;
      var message = this.$v.ayuda.$model.message;
      var checked = this.$v.ayuda.$model.checked;

      console.log(name,email,phone,subject,message,checked);

      if(this.$v.ayuda.$invalid || !checked === true){
        console.log('Something gone wrong');
      }else{
        axios.post('https://conficaravan.com/code/ayuda_contact.php', {
          name: name,
          email: email,
          phone: phone,
          subject: subject,
          message: message,
        }).then(function (response) {
          console.log(response);
        }).then(function(){
          document.getElementById('ayuda-error').classList.add('d-none');
          document.getElementById('ayuda-form-div').classList.add('d-none');
          document.getElementById('ayuda-form-div-ok').classList.remove('d-none');
          
        }).catch(function (error) {
          console.log(error);
        });
      }
      
    },
    restart(){
      var precio_div = document.getElementsByClassName('precio_box');
      var precio_div_1 = document.getElementById('precio-1');
      var i = 0;

      for (i = 0; precio_div.length > i; i++){
        precio_div[i].classList.add('d-none');
      }

      precio_div_1.classList.remove('d-none');
    },
    calcular_precio_gtag(){
      
      if(this.getCookie('_ga_HLWS15224B')){
        this.$gtag.event('calcular_precio', {
          'event_name': 'calcular_precio'
        })
      }

    }

  }
}


</script>



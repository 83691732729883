<template>
  <div id="conocenos">
    <div class="container-fluid">
      <div class="row align-items-top justify-content-center">
          <div class="col-12 bk_contratar p-4 text-center">
      
              <h1 class="text-white mt-3">Conócenos</h1>
              <p class="text-white">¿Qué es Conficaravan?</p>

          </div>
      </div>
      <div class="container">
        <div class="row align-items-center justify-content-center">

          <div class="col-12 text-center justify-items-center mt-4 mb-4">

            <p>Texto</p>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Conocenos',
  
}
</script>

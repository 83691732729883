<template>
  <div id="coberturas">
    <!-- MODAL Franquicias agrupadas -->
    <b-modal id="franquicias" centered size="lg" :hide-footer="true" :hide-header="true">
      
      <div class="text-center">
        <h2 class="color">Franquicias agrupadadas</h2>
        <p>En caso de daños generales sin contrario identificado, cuando se verifique que los mismos son los derivados de varios hechos, el asegurador aplicara la franquicia contratada en póliza por cada lado afectado del vehículo, aceptándose como un solo siniestro todos los daños que se ubiquen en esa parte del mismo. Quedaran excluidos en su totalidad los daños del vehículo que se hubieran producido con anterioridad a la contratación de la póliza.</p>
      </div>
      
    </b-modal>
    <!-- End Modal Franquicias agrupadas -->
    <!-- MODAL Valor declarado -->
    <b-modal id="valor" centered size="lg" :hide-footer="true" :hide-header="true">
      
      <div class="text-center">
        <h2 class="color">Valor declarado</h2>
        <p>Se hace constar en póliza el valor del vehículo asegurado en el momento de contratación, incluyendo todos los accesorios declarados. Se aportará copia de factura de compra del vehículo. En el caso de que el vehículo no sea nuevo, el valor declarado no podrá ser superior al valor de factura.</p>
      </div>
      
    </b-modal>
    <!-- End Modal Valor declarado -->
    <div class="container-fluid">
      <div class="row align-items-top justify-content-center">
          <div class="col-12 bk_contratar p-4 text-center">
      
              <h1 class="text-white mt-3">¿Qué coberturas tienen nuestros seguros para Autocaravanas y Campers?</h1>
              <p class="text-white">Como especialistas en el sector sabemos que tu autocaravana o camper es mucho más que un vehículo, también es tu hogar.</p>
              <p class="text-white">Por este motivo te ofrecemos unas coberturas específicas para que tú y los tuyos disfrutéis de vuestras aventures Van Life seguros.</p>

          </div>
      </div>
      <div class="container">

        <div class="row align-items-center justify-content-center mb-3 mt-3">

          <div class="col-12 text-center mb-4 mt-4">
            <h3 class="color">Pack Conficaravan, descubre nuestras coberturas exclusivas</h3>
            <p>Todas las coberturas del Pack Conficaravan están incluidas en todas las modalidades de seguro sin coste adicional.</p>
          </div>

          <!-- Coberturas list -->
          <div class="col-12 text-center">
            <div class="d-inline-block">
              <div class="text-start box_check">
                <h5 class="color">Asistencia en viaje mejorada</h5>
                <p>Para Europa y Ribereños del Mediterráneo, y ampliada desde el Km0 para España, Andorra, Francia y Portugal.</p>
              </div>
              <div class="text-start box_check">
                <h5 class="color">Lunas cubiertas</h5>
                <p>Todo tipo de lunas cubiertas sin franquicia, incluso si son de plástico o metacrilato.</p>
              </div>
              <div class="text-start box_check">
                <h5 class="color">Cobertura ampliada</h5>
                <p>Cobertura ampliada para equipaje (considerados efectos personales) y material deportivo hasta 2.000€</p>
              </div>
              <div class="text-start box_check">
                <h5 class="color">Accesorios incluídos</h5>
                <p>Incluida la camperización o accesorio fijo hasta 15.000€ a primer riesgo sin coste adicional.</p>
              </div>
              <div class="text-start box_check">
                <h5 class="color">Responsabilidad civil</h5>
                <p>Responsabilidad civil por perdida de la carga (bicis, esquís, tabla de surf, etc.)</p>
              </div>
              <div class="text-start box_check">
                <h5 class="color">Remolque incluido</h5>
                <p>Remolque hasta 750kg incluido.</p>
              </div>
              <div class="text-start box_check">
                <h5 class="color">Responsabilidad civil familiar</h5>
                <p>Responsabilidad civil familiar hasta 200.000€, por siniestro y año, en camping y/o zonas habilitadas.</p>
              </div>
              <div class="text-start box_check">
                <h5 class="color">Responsabilidad civil del tomador</h5>
                <p>Responsabilidad civil del tomador como peatón o ciclista aficionado.</p>
              </div>
              <div class="text-start box_check">
                <h5 class="color">Taller de libre elección</h5>
                <p>Libre elección de taller por parte del asegurado en caso de reparación.</p>
              </div>
              <div class="text-start box_check">
                <h5 class="color">Garantía de indemnización mejorada</h5>
                <p>Mejorada garantía de indemnización en caso de pérdida total y valor venal mejorado un 30%</p>
              </div>
            </div>
          </div>

          <!-- END Coberturas list -->

        </div>

        <!-- Bloque Coberturas -->

        <div class="row align-items-center justify-content-center mt-5 mb-5 p-3 pt-4 pb-4 rounded bk_gray">
          <div class="col-12 text-center mt-2 mb-3">
            <h2 class="color">Tu eliges la modalidad de seguro que mejor se adapta a ti</h2>
          </div>

          <div class="col-12 text-center grid">
            <div class="grid-item box_1 text-start">
              <h3 class="color">Terceros Basic</h3>
              <p class="fs-4">Desde 288 €</p>
              <p class="fst-italic">Terceros + lunas</p>
              <ul class="list-unstyled icon_list_left">
                <li><font-awesome-icon icon="check-circle" class="color" /> Responsabilidad civil obligatoria y voluntaria</li>
                <li><font-awesome-icon icon="check-circle" class="color" /> Defensa jurídica</li>
                <li><font-awesome-icon icon="check-circle" class="color" /> Asistencia en viaje ampliada</li>
                <li><font-awesome-icon icon="check-circle" class="color" /> Reclamación de daños</li>
                <li><font-awesome-icon icon="check-circle" class="color" /> Lunas (incluidos metacrilatos y claraboyas)</li>
                <li><font-awesome-icon icon="check-circle" class="color" /> Pack Conficaravan</li>
              </ul>
              <p class="text-center mt-3">
                <router-link to="/?m=1"  @click="calcular_precio_gtag()"  class="btn btn-primary" >Calcular Precio</router-link>
              </p>
            </div>
            <div class="grid-item box_1 text-start">
              <h3 class="color">Terceros Complet</h3>
              <p class="fs-4">Desde 348 €</p>
              <p class="fst-italic">Tercero básico más</p>
              <ul class="list-unstyled icon_list_left">
                <li><font-awesome-icon icon="check-circle" class="color" /> Robo e incendio</li>
                <li><font-awesome-icon icon="check-circle" class="color" /> Camperización y/o accesorios fijos</li>
                <li><font-awesome-icon icon="check-circle" class="color" /> Cobertura de material deportivo</li>
                <li><font-awesome-icon icon="check-circle" class="color" /> Choque con animales</li>
                <li><font-awesome-icon icon="check-circle" class="color" /> Daños meteorológicos</li>
                <li><font-awesome-icon icon="check-circle" class="color" /> Pack Conficaravan</li>
              </ul>
              <p class="text-center mt-3">
                <router-link to="/?m=1"  @click="calcular_precio_gtag()"  class="btn btn-primary" >Calcular Precio</router-link>
              </p>
            </div>

            <div class="grid-item box_1 text-start">
              <h3 class="color">Todo Riesgo</h3>
              <div class="row">
                <div class="col-6">
                  <p class="fs-4 mb-0">Desde 450 €</p>
                  <h4 class="fs-6">Franquicia 300€</h4>
                </div>
                <div class="col-6">
                  <p class="fs-4 mb-0">Desde 378 €</p>
                  <h4 class="fs-6">Franquicia 600€</h4>
                </div>
              </div>

              <div class="row d-none">
                <div class="col-12">
                  <h4 class="fs-5 mb-0">Franquicia 300€</h4>
                </div>
                <div class="col-12">
                  <h4 class="fs-5 mb-0">Franquicia 600€</h4>
                </div>
              </div>
              
              <p class="fst-italic">Terceros completo más</p>
              <ul class="list-unstyled icon_list_left">
                <li><font-awesome-icon icon="check-circle" class="color" /> Daños propios del vehículo<br>(Franq. 300€/600€)</li>
                <li><font-awesome-icon icon="check-circle" class="color" /> Valor declarado  <font-awesome-icon icon="info-circle" class="hover_icon" style="margin-left: 0px !important" data-bs-toggle="button" v-b-modal.valor/></li>
                <li><font-awesome-icon icon="check-circle" class="color" /> Franquicias agrupadas <font-awesome-icon icon="info-circle" class="hover_icon" style="margin-left: 0px !important" data-bs-toggle="button" v-b-modal.franquicias/></li>
                <li><font-awesome-icon icon="check-circle" class="color" /> Pack Conficaravan</li>
              </ul>
              <p class="text-center mt-3">
                <router-link to="/?m=1"  @click="calcular_precio_gtag()"  class="btn btn-primary" >Calcular Precio</router-link>
              </p>
            </div>
          </div>
        </div>

        <!-- END Bloque Coberturas -->

        <div class="row align-items-center justify-content-center mb-4">
          <div class="col-sm-4">
            <img src="../assets/img/img_4.jpg" class="img-fluid img-confi">
          </div>
          <div class="col-sm-8">
            <h3 class="color">¿Tienes dudas?</h3>
            <p>Consulta nuestra página de preguntas frecuentes en la que aclaramos la mayoría de dudas sobre nuestro seguro para autocaravanas y campers.</p>
            <p class="text-center">
              <router-link to="/faq" class="btn btn-primary">Preguntas frecuentes</router-link>
            </p>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'coberturas',
  mounted: function () {
    window.scrollTo( 0, 0 );  
  },
  methods: {
    calcular_precio_gtag(){
      this.$gtag.event('calcular_precio', {
        'event_name': 'calcular_precio'
      })
    }
  }
  
}
</script>

<template>
    <div class="container-fluid">
        <div class="row align-items-center justify-content-center">
            <div class="col-12 bk_footer pt-3 pb-3">
                <div class="container">
                    <div id="footer" class="row align-items-center justify-content-center pb-4 pt-4" >
                        <div class="col-3">
                            <ul class="list-unstyled text-start fs-5" v-if="this.$route.path != '/presupuesto_seguro'">
                                <li class="list-item">
                                    <router-link to="/" >Inicio</router-link>
                                </li>
                                <li class="list-item">
                                    <router-link to="/confirmar" >Presupuesto</router-link>
                                </li>
                                <li class="list-item">
                                    <a href="https://www.confisergur.com/es/nosotros/" >Conócenos</a>
                                    <router-link class="d-none" to="/conocenos" >Conócenos</router-link>
                                </li>
                                <li class="list-item">
                                    <router-link to="/faq" >Preguntas frecuentes</router-link>
                                </li> 
                                <li class="list-item">
                                    <router-link to="/asistencia" >Asistencia</router-link>
                                </li>
                                <li class="list-item">
                                    <router-link to="/aviso_legal" >Aviso legal</router-link>
                                </li>
                                <li class="list-item">
                                    <router-link to="/cookies" >Cookies</router-link>
                                </li>

                                <li class="list-item">
                                    <router-link to="/transparencia" >Transparencia</router-link>
                                </li>
                                <li class="list-item">
                                    <router-link to="/privacidad" >Privacidad</router-link>
                                </li>
                                <!-- <li class="list-item">
                                    <router-link to="/cookies">Cookies</router-link>
                                </li> -->
                                <li class="list-item d-none">
                                    <router-link to="/" >Sitemap</router-link>
                                </li>
                            </ul>
                        </div>
                        <div class="col-6 text-center">
                            <a class="btn btn primary" href="https://www.instagram.com/conficaravan_/"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'instagram' }" class="fs-1"/><br>Instagram</a>
                            <a class="btn btn primary" href="https://www.facebook.com/ConfiCaravan"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'facebook' }" class="fs-1"/><br>Facebook</a>
                        </div>
                        <div class="col-3">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="footer_bottom" class="text-center p-3">
            <a href="https://confisergur.com" target="_blank">
                <img src="../assets/img/logo_confisergur.png" class="img-fluid" style="max-height:60px;">
                <p class="small text-black">Conficaravan® es una marca registrada propiedad de Confisergur®</p>
            </a>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Footer',
//   props: {
//     msg: String
//   }
}
</script>
<template>
  <div id="faq">
    <div class="container-fluid">
      <div class="row align-items-top justify-content-center">
          <div class="col-12 bk_contratar p-4 text-center">
      
              <h1 class="text-white mt-3">Te aclaramos todas tus dudas sobre nuestro seguro Conficaravan</h1>
              <p class="text-white">Queremos darte respuesta a las preguntas más frecuentes.</p>

          </div>
      </div>
      <div class="container">
        <div class="row align-items-center justify-content-center">

          <div class="col-12 text-center justify-items-center mt-4 mb-4">

            <div class="accordion" role="tablist" v-for="(questionsFaq, index) in questionsFaq" :key="index" :id="'collapse' + index" >

              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block v-b-toggle="'collapse' + index" class="text-start fs-5">{{ questionsFaq.question }}</b-button>
                </b-card-header>
                <b-collapse :id="'collapse' + index" :accordion="'faq_list' + index" role="tabpanel" class="text-start">
                  <b-card-body>
                    <b-card-text v-for="(questiontext, index) in questionsFaq.text" :key="index">{{ questiontext }}</b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import questionsFaq from '../assets/json/faq.json'

export default {
  name: 'faq',
  data() {
    return {
      questionsFaq: questionsFaq, 
      text: 'Lorem ipsum'
    }
  },
  mounted: function () {
    window.scrollTo( 0, 0 );  
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

import { render, staticRenderFns } from "./PrecioSeguroFormulario.vue?vue&type=template&id=009b380a&scoped=true"
import script from "./PrecioSeguroFormulario.vue?vue&type=script&lang=js"
export * from "./PrecioSeguroFormulario.vue?vue&type=script&lang=js"
import style0 from "./PrecioSeguroFormulario.vue?vue&type=style&index=0&id=009b380a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "009b380a",
  null
  
)

export default component.exports
<template>
  <div id="privacidad">
    <div class="container-fluid">
      <div class="container">
        <div class="row align-items-center justify-content-center">

          <div class="col-12 text-start">

            <h3 class="color text-center">POLÍTICA DE PRIVACIDAD</h3>


            <p class="color h5" style="margin-top: 50px">RESPONSABLE DEL TRATAMIENTO</p>
              <ul style="line-height: 30px; margin-left: 40px"> 
              <li> • Titular: <b> CONFISERGUR DE GESTIÓ ROVIRA I ASSOCIATS, S.L.</b> (en adelante, La Correduría)</li> <br>
              <li> • Domicilio social: Carrer Camí de la Tolosa, 9 desp. 6 Barcelona </li> <br>
              <li> • CIF: B-62867387</li><br>
              <li> • <b>Teléfono: 938 892 969</b> </li><br>
              <li> • <b>E-mail: info@confisergur.com</b> </li><br>
            </ul>

 

            <p class="color h5" style="margin-top: 50px">DATOS PERSONALES RECOPILADOS Y TRATADOS</p>
            <p>Los datos personales que recopilamos y tratamos los obtenemos directamente de usted o su representante legal, a través de formularios, comunicándose con nosotros por correo postal, teléfono, correo electrónico, WhatsApp, u de otro modo. </p>
            <p>Toda la información que nos facilite deberá ser veraz, responsabilizándose de todos aquellos datos que nos comunique y debiendo mantener la información perfectamente actualizada de forma que responda, en todo momento, a la situación real. En todo caso, la persona que facilite la información será la única responsable de las manifestaciones falsas o inexactas que realice y de los perjuicios que cause a LA CORREDURÍA, o a terceros por la información que facilite.</p>
            <p>En el caso de proporcionarnos información sobre otras personas debe obtener su consentimiento. Esto es aplicable a todos los datos, y especialmente, si los hubiera, a datos sensibles como los relativos a sanciones administrativas. Si nos proporciona datos de otras personas, nos está confirmando que tiene su permiso y que estas personas han comprendido como se utilizará dicha información.</p>
            <p> <b> Los datos personales que podemos recopilar y tratar sobre usted son los siguientes: </b> </p>

            <ul style="line-height: 30px; margin-left: 40px"> 
              <li><strong> • Datos Identificativos</strong>: Nombre, dirección, correo electrónico, números de teléfono, sexo, estado civil, fecha y lugar de nacimiento, nacionalidad, detalles familiares (por ejemplo, en segundos conductores vinculados a la póliza), DNI, Pasaporte, Carnet de conducir, matrícula, marca y modelo del vehículo, fecha de compra del vehículo, titularidad del vehículo, existencia de renting o crédito bancario sobre el mismo, grabación de llamadas, etc. Datos de navegación en nuestra web (por ejemplo, dirección IP).</li>
              
              <li><strong> • Información financiera</strong>: Detalles de la cuenta bancaria o tarjeta de pago, u otros medios seguros de pago (por ejemplo, PayPal).</li>
              
              <li><strong> • Detalles del riesgo: </strong>Información que debemos recopilar para evaluar el riesgo cuya cobertura se solicita y facilitar los presupuestos de las compañías aseguradoras que más se ajusten a sus necesidades. Esto puede incluir retiradas de carnet de conducir, historial de siniestros con/sin responsabilidad, anulaciones por parte de otra aseguradora, uso del vehículo (particular o de empresa), para los seguros de coches clásicos, kilómetros actuales del vehículo.</li>
              
              <li><strong> • Datos de crédito y antifraude: </strong>Historial crediticio, sanciones y delitos, e información recibida de varias bases de datos contra el fraude relacionadas con usted (Agencias de referencia crediticia - ficheros de solvencia patrimonial y crédito).</li>
              
              <li><strong> • Información de Servicios: </strong>Información sobre las cotizaciones que recibe y las pólizas que contrata.</li>
              
              <li><strong> • Asesoramiento en caso de siniestro: </strong>Existencia o no de lesiones corporales en caso de accidente (esta información se recaba a los solos efectos de asesorar al tomador sobre la gestión del siniestro con la compañía aseguradora, no recabándose información sobre la naturaleza y alcance de las lesiones).</li>
              
              <li><strong> • Reclamaciones anteriores y actuales: </strong>Información sobre reclamaciones anteriores y actuales (incluidos otros seguros no relacionados), que pueden incluir datos de carácter personal.</li>
              
              <li><strong> • Categorías especiales de datos personales: </strong>Datos relativos a sanciones y condenas penales.</li>
              
              <li><strong> • Datos de marketing y comunicaciones: </strong>Su deseo y consentimiento para recibir nuestras comunicaciones comerciales y de terceros, así como sus preferencias de comunicación.</li>
              
              <li><strong> • Datos Estadísticos y Demográficos: </strong>También recopilamos, usamos y compartimos datos agregados tales como datos estadísticos o demográficos para cualquier propósito. Los datos agregados pueden derivarse de sus datos personales, pero no se consideran datos personales según la normativa ya que estos datos no revelan su identidad directa o indirectamente.&nbsp;</li>
            </ul>

            <p> <b>Las finalidades para las cuales recopilamos y tratamos sus datos personales son las siguientes:</b></p>
            <p> Solo utilizaremos sus datos personales para los fines para los que han sido recogidos, a menos que consideremos razonablemente que debemos usarlos por alguna otra razón y esa razón sea compatible con el propósito original.</p>            
            <p> En el supuesto de que necesitáramos utilizar su información personal para fines no relacionados, se lo notificaremos con la antelación suficiente y le explicaremos la base legal que nos permite hacerlo.</p>

            <ul style="line-height: 30px; margin-left: 40px"> <li> <b> • Inicio de la relación de Corretaje </b></li></ul>

            <p>Adquisición de la condición de cliente.</p>
            <p>Evaluar los riesgos a cubrir y valorar la póliza / prima adecuada.</p>
            <p>Asesoramiento objetivo sobre los distintos productos de seguros que mejor se adapten a sus necesidades.</p>
            <p>Detección y prevención del fraude.</p>
            <p>En su caso, gestión de pago de la prima a la compañía aseguradora.</p>


            <ul style="line-height: 30px; margin-left: 40px"> <li> <b> • Administración de Pólizas </b></li></ul>
            <p>Atención al cliente, incluida la comunicación por cualquier medio y el envío de actualizaciones u otras comunicaciones relacionadas con su póliza.</p>
            <p>Gestión de Pagos.</p>
            <p>Realizar controles de calidad en relación a los servicios prestados respecto al seguro contratado.</p>


            <ul style="line-height: 30px; margin-left: 40px"> <li> <b> • Atención y Gestión de reclamaciones </b></li></ul>
            <p>Gestión de reclamaciones de seguros y reaseguros.</p>
            <p>Defensa ante la compañía aseguradora.</p>
            <p>Asesoramiento en el ejercicio o la defensa de las reclamaciones de cualquier tipo que pudieran derivarse de la relación contractual mantenida.</p>
            <p>Investigación de fraude.</p>


            <ul style="line-height: 30px; margin-left: 40px"> <li> <b> • Renovaciones </b></li></ul>
            <p>Contactar al asegurado / titular de la póliza para renovar la póliza de seguro.</p>
            <p>Asesorarle como cliente de manera objetiva sobre los distintos productos de seguros que mejor se adapten a sus necesidades.</p> 
            <p>En su caso, gestión de Pago de la prima a la compañía aseguradora.</p>


            <ul style="line-height: 30px; margin-left: 40px"> <li> <b> • Otros fines fuera del ciclo de vida del seguro, pero necesarios para la provisión de seguro durante todo el ciclo de vida del mismo </b></li></ul>
            <p>Cumplir con nuestras obligaciones legales y regulatorias.</p>

            <ul style="line-height: 30px; margin-left: 40px"> <li> <b> • Marketing y Publicidad </b></li></ul>
            <p> Para hacerle sugerencias y recomendaciones sobre productos, servicios u ofertas directamente relacionadas con el sector asegurador que pueden interesarle.</p>
            <p> Para ofrecerle información relativa a concursos, promociones, eventos o similares.</p>
            <p> Para hacerle sugerencias y recomendaciones de temas relacionados indirectamente con los seguros, como pueden ser temas de salud, automoción o cualquier tema que esté de moda relacionado. </p>
            <p><b>Recopilamos sus datos personales a través de los siguientes medios:</b></p>
            <ul style="line-height: 30px; margin-left: 40px">
              <li>• Nuestra página web (incluso mediante el uso de cookies). </li> <br> 
              <li>• Por teléfono (grabación de llamadas). </li> <br> 
              <li>• Por correo electrónico. </li> <br> 
              <li>• Páginas web de comparadores de precios de seguros a los que haya accedido buscando presupuesto. </li> <br> 
              <li>• Formularios. </li> <br> 
              <li>• Ferias y/o eventos organizados sobre el sector. </li> <br> 
              <li>• Fuentes de acceso público.      </li> <br> 
            </ul>



            <p class="color h5" style="margin-top: 50px">BASES LEGALES PARA EL TRATAMIENTO DE SUS DATOS</p>
            <p>Las bases legales que justifican el tratamiento de sus datos para las finalidades especificadas, son las siguientes:</p>
            <p> <b> Consentimiento explícito</b></p>
            <p>Usted ha otorgado su consentimiento explícito para el tratamiento de dichos datos personales para uno o más propósitos específicos, en los que no podemos obtener, proporcionar o administrar una cobertura de seguro sin este consentimiento.</p>
            <p> Usted es libre de retirar su consentimiento contactando con nuestro Responsable de Tratamiento. La retirada de este consentimiento puede afectar a nuestra capacidad para contratar o gestionar un seguro o ayudar con el pago de las reclamaciones.</p>

            <p><b> Ejecución del contrato suscrito con usted</b></p>
            <p>El tratamiento es necesario para la celebración y/o ejecución de un contrato del que sea parte. </p>


            <p><b>Cumplimiento de una obligación legal</b></p>
            <p>El tratamiento es necesario para cumplir con una obligación legal a la que estemos sujetos.</p>


            <p><b>En interés público</b></p>
            <p>El tratamiento es necesario para la realización de una tarea llevada a cabo en interés público.</p>

            <p><b>Para nuestros intereses legítimos</b></p>
            <p>El tratamiento es necesario para los intereses legítimos que persigue LA CORREDURÍA o un tercero, excepto cuando dichos intereses sean anulados por sus intereses o derechos y libertades fundamentales, que requieren una protección de datos personales.</p>


            <p><b>Para reclamaciones legales</b></p>
            <p>El tratamiento es necesario para el establecimiento, el ejercicio o la defensa de reclamaciones legales o cuando los tribunales ejerzan sus competencias.</p>




            <p class="color h5" style="margin-top: 50px">TIEMPO DE GUARDADO DE SUS DATOS PERSONALES</p>
            <p>Guardaremos sus datos personales únicamente durante el tiempo que sea necesario y para las finalidades para las que se recopilaron originalmente.</p>
            <p>Tendremos que guardar los datos mientras haya alguna posibilidad de presentar una reclamación legal al amparo del seguro contratado por ambas partes, o cuando se nos exija guardar sus datos personales debido a razones legales o reglamentarias (Ley de Contrato de Seguro, Ley de ordenación y supervisión de los seguros privados, Ley de Mediación en Seguros y Reaseguros Privados, Ley sobre comercialización a distancia de servicios financieros destinados a los consumidores, Ley sobre responsabilidad civil y seguro en la circulación de vehículos a motor, normativa de distribución, así como cualquier otra que fuera de aplicación).</p>
            <p>No obstante, los datos permanecerán en situación de bloqueo durante los plazos que determina la normativa especificada anteriormente, a fin de poder atender las responsabilidades que se pudieran generar. Transcurridos dichos plazos, se procederá a su supresión, salvo que el interesado haya autorizado su tratamiento para finalidades concretas por un plazo superior.</p>
            <p>En algunas circunstancias, podemos anonimizar sus datos personales (para que ya no puedan asociarse con usted) con fines de investigación o estadísticos, en cuyo caso podremos utilizar esta información indefinidamente sin previo aviso.</p>


            <p class="color h5" style="margin-top: 50px">COMUNICACIÓN DE SUS DATOS PERSONALES A TERCEROS</p>
            <p>Podemos comunicar su información personal a los terceros que se detallan a continuación para los estrictos fines descritos en la presente Política de Privacidad.</p>
            <p>Puede contactar con nosotros para obtener más información en torno a la comunicación de su información personal.</p>
            <p>Los terceros enumerados a continuación solo utilizarán su información personal bajo nuestra estricta instrucción y tienen la obligación de garantizar que se implementen las medidas de seguridad adecuadas.</p>

            <ul style="line-height: 30px; margin-left: 40px">
              <li> • Aseguradoras. </li> <br>
              <li> • Reaseguradoras. </li> <br>
              <li> • Proveedores de bases de datos antifraude y sanciones.</li> <br> 
              <li> • Agencias de referencia crediticia - ficheros de solvencia patrimonial y crédito. </li> <br>
              <li> • En caso de impago por parte del Cliente, LA CORREDURÍA podrá ceder sus datos a empresas encargadas de recobro de las deudas cumpliendo la normativa aplicable. </li> <br>
              <li> • Empresas de servicios informáticos a compañías aseguradoras. </li> <br>
              <li> • Asesores profesionales que incluyen auditores, abogados, asesores fiscales, peritos, expertos y agencias de ventas de medios. </li> <br>
              <li> • Gestores de reclamaciones. </li> <br>
              <li> • Terceros involucrados en reclamaciones / investigaciones / procesamientos. </li> <br>
              <li> • Investigadores privados. </li> <br>
              <li> • Fuerzas y Cuerpos de Seguridad del Estado. </li> <br>
              <li> • Jueces, Tribunales y otros órganos de la Administración pública. </li> <br>
              <li> • Proveedores de servicios de administración de sistemas y TI. </li> <br>
              <li> • Organizadores de eventos, expositores de eventos y cualquier otro tercero que participe en la organización o en la contribución de eventos de LA CORREDURÍA. </li> <br>
              <li> • Plataformas de Comunicación y Empresas de Marketing/Publicidad. </li> <br>
              <li> • Bancos / Cajas de ahorro. </li> <br>
              <li> • Proveedores telefónicos utilizados como parte del servicio de Atención al Cliente. </li> <br>
              <li> • Entidades de encuestas de satisfacción. </li> <br>
              <li> • Proveedores de software / sistemas. </li> <br>
              <li> • Dirección General de Seguros y Fondos de Pensiones u otros Órganos de la Administración Pública con competencia en la materia. </li> <br>
              <li> • Órgano de la Administración con autoridad sobre nosotros o usted, como cuando hay una orden judicial u obligación legal.</li> <br>
            </ul>




            <p class="color h5" style="margin-top: 50px">TRANSFERENCIAS INTERNACIONALES</p>
            <p>En la actualidad LA CORREDURIA no realiza transferencia internacional alguna de datos personales fuera de la Unión Europea.</p>
            <p>No obstante, y si en un futuro tuviera lugar, queremos asegurarnos de que sus datos se almacenan y se transfieren de forma segura. Por lo tanto, solamente los transferiremos fuera de la Unión Europa, cuando se cumpla con la legislación sobre protección de datos y los medios de transferencia ofrezcan garantías adecuadas en relación con sus datos, por ejemplo:</p>

            <ul style="line-height: 30px; margin-left: 40px">
              <li>• Mediante un acuerdo de transferencia de datos que incorpore las cláusulas contractuales tipo en vigor establecidas por la Comisión Europea para la transferencia de datos personales por los responsables del tratamiento en el EEE a los responsables y encargados del tratamiento en territorios que carezcan de leyes adecuadas de protección de datos; o</li><br>
              <li>• Suscribiendo el marco del Escudo de la Privacidad UE - EE. UU. para la transferencia de datos personales desde entidades con sede en la UE hasta entidades ubicadas en Estados Unidos u otro acuerdo equivalente en relación con otros territorios; o</li><br>
              <li>• Transfiriendo estos datos a países respecto de los cuales la Comisión Europea haya adoptado una decisión de adecuación relativa al grado de protección que les confiere la legislación; o</li><br>
              <li>• Cuando sea necesario para la formalización o la ejecución de un contrato entre nosotros y un tercero, y la transferencia redunde en interés de su persona a los efectos de dicho contrato (por ejemplo, si debemos transferir los datos fuera del EEE para cumplir con nuestras obligaciones con arreglo a dicho contrato si es usted cliente nuestro); o</li><br>
              <li>• Cuando haya dado su consentimiento para la transferencia de los datos.</li><br>
            </ul>
            <p>Para garantizar que su información personal recibe un nivel de protección adecuado, pondremos en práctica los procedimientos adecuados junto con los terceros con quienes intercambiemos sus datos personales, para garantizar que dichos terceros traten dicha información personal de manera coherente con la legislación sobre protección de datos y que la respeten.</p>




            <p class="color h5" style="margin-top: 50px">ELABORACIÓN DE PERFILES Y DECISIONES AUTOMATIZADAS</p>
            <p>Al calcular las primas de seguros, los miembros del mercado de seguros (aseguradoras y reaseguradoras) pueden comparar sus datos personales con los promedios/estadísticas de la industria. Sus datos personales también se pueden usar para crear promedios/estadísticas de la industria en el futuro. Esto se conoce como "elaboración de perfiles" y se utiliza para garantizar que las primas reflejen el riesgo.</p>
            <p> También podemos tratar sus datos para elaborar un perfil comercial a partir de sus preferencias personales que nos permita ofrecerle productos de seguros, por cualquier medio, incluidos los electrónicos y redes sociales. </p>
            <p>Los miembros del mercado de seguros podrían tomar algunas decisiones basadas en el perfil y sin intervención del personal (lo que se conoce como toma de decisiones automatizadas). En dicho caso, los participantes del mercado de seguros proporcionarán detalles de cualquier decisión automatizada en sus avisos de información (y previa solicitud), que incluirán: </p>

            <ul style="line-height: 30px; margin-left: 40px">
              <li> • Donde usan esa toma de decisiones automatizada. </li> <br> 
              <li> • La lógica aplicada.</li> <br> 
              <li> • Las consecuencias de la toma de decisiones automatizada. </li> <br> 
              <li> • El derecho de obtener intervención humana, a expresar su punto de vista, a recibir una explicación de la decisión tomada tras la evaluación, y a impugnar la misma.</li> <br> 
            </ul>
           <!-- <p class="color h5" style="margin-top: 50px">DATOS PERSONALES RECOPILADOS Y TRATADOS</p>
            <p> </p> -->



            <p class="color h5" style="margin-top: 50px">MEDIDAS DE SEGURIDAD DE LOS DATOS PERSONALES</p>
            <p> Hemos implementado las medidas de seguridad técnicas y organizativas adecuadas para evitar que sus datos personales se pierdan, se utilicen o se acceda a ellos accidentalmente de forma no autorizada, sean modificados o divulgados, todo ello conforme a lo dispuesto en el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales, así como en la Ley Orgánica 3/2018, de 5 de diciembre de Protección de Datos Personales y garantía de los derechos digitales, y demás normativa del sector aplicable. </p>
            <p> Asimismo, hemos implementado procedimientos para tratar cualquier sospecha de violación de la seguridad de los datos personales. En caso de producirse, se lo notificaremos a usted y a la entidad reguladora pertinente (Agencia Española de Protección de Datos).</p>
            <p>Aun así, cualquier transmisión de datos realizada a través de internet está bajo su propia responsabilidad y riesgo, por lo que le instamos a tomar todas las precauciones para proteger su información personal mientras esté en línea. </p>


            <p class="color h5" style="margin-top: 50px">SUS DERECHOS</p>
            <p>Usted puede ejercitar gratuitamente sus Derechos de Acceso, Rectificación, Supresión, Oposición y Limitación al tratamiento de sus datos, así como a la portabilidad de los mismos y a no ser objeto de una decisión basada únicamente en el tratamiento automatizado, incluida la elaboración de perfiles. </p>
            <p>En concreto, tiene derecho a: </p>

            <ul style="line-height: 30px; margin-left: 40px">
 

              <li>• Que le proporcionemos más detalles sobre el uso que hacemos de sus datos personales / categoría especial de datos. </li> <br> 
              <li>• Que le facilitemos el acceso a sus datos personales, obteniendo incluso una copia de la información personal que usted nos haya proporcionado. </li> <br> 
              <li>• Que actualicemos cualquier inexactitud en sus datos personales. </li> <br> 
              <li>• Que eliminemos cualquier categoría especial de datos personales / datos de carácter personal que ya no tengan una base legal para su uso. </li> <br> 
              <li>• Cuando el tratamiento se base en el consentimiento expreso, retirar dicho consentimiento prestado (sin efectos retroactivos) a fin de que cesemos el tratamiento basado en dicho consentimiento. </li> <br> 
              <li>• Oponerse a cualquier tratamiento basado en el interés legítimo, a menos que nuestros motivos para llevar a cabo ese tratamiento superen cualquier perjuicio a sus derechos de protección de datos.</li> <br>  
              <li>• Restringir el modo en que utilizamos sus datos personales mientras se está investigando una denuncia. </li> <br> 
              <li>• Recibir sus datos personales en un formato electrónico estructurado y de uso habitual y poder transmitirlos a otro responsable. </li> <br> 
              <li>• Solicitar su baja en la recepción de comunicaciones comerciales.</li> <br> 
            </ul>

            <p> En determinadas circunstancias, nos veremos obligados a restringir los derechos anteriores para salvaguardar el interés público (por ejemplo, la prevención o detección de delitos) y nuestros intereses (por ejemplo, el mantenimiento del privilegio legal).</p>
            <p>El ejercicio de los derechos es personalísimo, por lo que para su ejercicio deberá acreditar su identidad o la de su Representante Legal. En el supuesto de que no acredite debidamente su identidad o existan dudas en torno al derecho ejercitado, nos pondremos en contacto con usted para aclarar dichos extremos. </p>
            <p>Si usted desea ejercitar cualquiera de los derechos enunciados anteriormente, por favor, póngase en contacto con nosotros a través de las direcciones arriba indicadas, adjuntando la petición de solicitud, fechada y firmada, así como fotocopia de un documento identificativo válido y un domicilio a efectos de notificaciones. </p>


            <p style="margin-bottom: 100px;"></p>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
